import { AppState } from "@/interfaces/base";
import { AuthMethod, AuthType } from "@/interfaces/user";
import { RootState, useAppDispatch } from "@/redux/app.store";
import { appAuthThunk } from "@/redux/slices/auth";
import { Button, useToast } from "@chakra-ui/react";
import { ChevronRight, Github, Google } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function AuthWith({
  title,
  type,
}: {
  title?: string;
  type: AuthType;
}) {
  const dispatch = useAppDispatch();
  const { state } = useSelector((state: RootState) => state.authReducer);
  const toast = useToast();
  let navigate = useNavigate();

  async function google() {
    let x = await dispatch(
      appAuthThunk({
        type: type,
        method: AuthMethod.Google,
        payload: null,
      })
    );

    if (appAuthThunk.fulfilled.match(x)) {
      navigate("/dashboard", { replace: true });
      toast({
        description: "success",
      });
    } else {
      toast({
        title: x.error.message,
      });
    }
  }

  async function github() {
    let x = await dispatch(
      appAuthThunk({
        type: AuthType.SignIn,
        method: AuthMethod.Github,
        payload: null,
      })
    );

    if (appAuthThunk.fulfilled.match(x)) {
      navigate("/dashboard", { replace: true });
      toast({
        description: "success",
        status: "success",
      });
    } else if (appAuthThunk.rejected.match(x)) {
      toast({
        description: x.error.message,
        status: "error",
      });
    }
  }

  return (
    <div>
      {title ?? <p className="m-2 text-faded ">{title}</p>}
      <div className="flex gap-4">
        <Button
          disabled={state === AppState.loading}
          onClick={github}
          leftIcon={<Github />}
          rightIcon={<ChevronRight />}
          background={"transparent"}
          boxShadow={"sm"}
          variant={"outline"}
        >
          Sign {type === AuthType.SignIn ? "in" : "up"} with Github
        </Button>

        <Button
          disabled={state === AppState.loading}
          onClick={google}
          leftIcon={<Google />}
          rightIcon={<ChevronRight />}
          background={"transparent"}
          boxShadow={"sm"}
          variant={"outline"}
        >
          Sign {type === AuthType.SignIn ? "in" : "up"} with Google
        </Button>
      </div>
    </div>
  );
}
