import { Session } from "@/interfaces/session";
import { Colors } from "@/utils/colors";
import {
  Avatar,
  AvatarGroup,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Flex,
} from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";
import {
  Calendar2,
  Calendar2Plus,
  CameraVideoFill,
  Clock,
  Link,
} from "react-bootstrap-icons";

interface SessionItemProp extends Session {
  buttonTitle?: string,
  buttonIcon?: ReactElement,
}

function SessionItem(props: SessionItemProp) {
  return (
    <div>
      <Card className="w-[450px] rounded-xl shadow-[0px 10px 40px 10px rgba(0, 0, 0, 0.05)]">
        <CardHeader paddingBottom={0}>
          <div className="flex justify-between">
            <AvatarGroup>
              <Avatar></Avatar>
              <Avatar></Avatar>
              <Avatar></Avatar>
              <Avatar></Avatar>
            </AvatarGroup>

            <div>
              <Button
                variant={"ghost"}
                textColor={Colors.violet600}
                className="font-bold text-violet-600"
                fontWeight={"bold"}
              >
                {props.title}
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody padding={0}>
          <Container className="py-4" maxW="full">
            <p className="font-medium"> {props.description} </p>
          </Container>

          <Container className="py-4 border-y border-gray-100" maxW="full">
            <Flex
              direction={"row"}
              justify={"space-between"}
              alignItems={"center"}
              border={"gray"}
            >
              <Flex direction={"row"} gap={"10px"} alignItems={"center"}>
                <Calendar2 /> <span className="font-bold"> {props.date}</span>
              </Flex>
              <Flex direction={"row"} gap={"10px"} alignItems={"center"}>
                <Clock /> <span className="font-bold"> {props.time}</span>
              </Flex>
            </Flex>
          </Container>
        </CardBody>
        <CardFooter className="flex justify-end gap-4">
          <Button variant={"outline"} borderColor={Colors.black}>
            <Calendar2Plus />
          </Button>
          <Button
            variant={"outline"}
            borderColor={Colors.black}
            leftIcon={props.buttonIcon ?? <Link />}
          >
            {props.buttonTitle ?? 'Join session'}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

const cardStyle = {
  background: '#FFFFFF',
  boxShadow: '0px 10px 40px 10px rgba(0, 0, 0, 0.05)',
  borderRadius: '12px',
}
export default SessionItem;
