import { AppState } from "@/interfaces/base";
import { TeamMember } from "@/interfaces/timeline";
import { HacklabTeam } from "@/interfaces/user";
import { FirebaseAuth } from "@/services/auth";
import { Firestore } from "@/services/firebase";
import { AppConstants } from "@/utils/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, doc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";

interface TeamState {
    hasTeam: boolean,
    team: null | HacklabTeam,
    isAdmin: null | boolean,
    status: AppState,
    error?: string,
}

const initialState: TeamState = {
    hasTeam: false,
    team: null,
    isAdmin: false,
    status: AppState.idle
};

export const getTeamThunk = createAsyncThunk('teams/getTeam', async (uid: string, { dispatch }) => {
    let ref = collection(Firestore, 'teams',);
    // console.log(uid)

    let q = query(ref, where('memberIds', 'array-contains', uid))
    const res = await getDocs(q)
    if (res.empty) {
        let x: TeamState = {
            hasTeam: false,
            isAdmin: false,
            team: null,
            status: AppState.success
        }
        return x
    } else {
        const team = res.docs[0].data();
        // console.log(res.docs[0].exists())
        let x: TeamState = {
            hasTeam: res.docs[0]?.exists(),
            isAdmin: team['ownerId'] === uid,
            team: team as HacklabTeam,
            status: AppState.success
        }
        return x
    }
})

type CreateTeam = HacklabTeam & { owner: TeamMember }

export const createTeamThunk = createAsyncThunk('team/create', async (payload: CreateTeam, { dispatch }) => {

    let id = Math.floor(Math.random() * 1000000)
    let path = doc(Firestore, AppConstants.teamCol + '/' + id.toString());

    let member: TeamMember & any = {
        image: payload.owner.image ?? '',
        name: payload.owner.name ?? '',
        uid: payload!.ownerId!,
        role: '',
        joinOn: new Date()
    }
    payload.owner = payload.owner.name as any;
    try {
        const data: HacklabTeam = {
            ...payload,
            memberIds: [payload!.ownerId!],
            members: [member],
            id: id.toString(),
            ownerId: payload!.ownerId!,
        }
        await setDoc(path, data);

        dispatch(FirebaseAuth.currentUser as any)
        return data;
    } catch (error) {

        return error;
    }

})

export const updateTeamThunk = createAsyncThunk('team/update', async (payload: CreateTeam, { dispatch }) => {
    // console.log(payload)
    if (!payload.id) {
        throw Error('Can not update team')
    }
    let id = payload.id!
    let path = doc(Firestore, AppConstants.teamCol + '/' + id.toString());

    try {
        const data: Partial<HacklabTeam> = {
            name: payload.name,
            alias: payload.alias,
            interests: payload.interests,
            description: payload.description,
        }
        await updateDoc(path, data);

        dispatch(FirebaseAuth.currentUser as any)
        return data;
    } catch (error) {

        return error;
    }

})


const teamReducer = createSlice({
    name: 'team',
    initialState: initialState,
    reducers: {
        setTeam(state, data) {
            state.isAdmin = data.payload.isAdmin;
            state.team = data.payload.team;
        },
        removeTeam(state) {
            state.team = null;
            state.isAdmin = null;
        },
        updateTeam(state, data) {
            state.team = { ...state.team, ...data.payload.team }
            state.isAdmin = data.payload.isAdmin;
        }
    },
    extraReducers(builder) {
        builder.addCase(getTeamThunk.pending, (state,) => {
            state.hasTeam = false;
            state.status = AppState.loading;
        })
        builder.addCase(getTeamThunk.fulfilled, (state, { payload }) => {
            // let x:TeamState =  
            state.team = payload.team;
            state.status = AppState.success;
            state.hasTeam = payload.hasTeam;
            state.error = undefined;
            state.status = AppState.success
            state.isAdmin = payload.isAdmin
        })
        builder.addCase(getTeamThunk.rejected, (state, { payload, error, }) => {
            state.status = AppState.error;
            state.error = error.message;
            state.hasTeam = false;
            state.team = null;
        })
        builder.addCase(createTeamThunk.fulfilled, (state, { payload, }) => {
            state.status = AppState.success;
            // state.team = payload.team;
            // state.hasTeam = payload.team!.members.includes(payload.team!.teamOwnerId!) ?? false;
            // state.isAdmin = payload.team?.teamOwnerId ?? false;
        })
        builder.addCase(createTeamThunk.pending, (state, { payload }) => {
            state.status = AppState.loading;
            state.team = null;
            state.hasTeam = false;
            state.isAdmin = false;
        })
        builder.addCase(createTeamThunk.rejected, (state, { payload }) => {
            state.status = AppState.error;
            state.team = null;
            state.hasTeam = false;
            state.isAdmin = false;
        })
    }
})


export const { setTeam, removeTeam, updateTeam } = teamReducer.actions

export const TeamReducer = teamReducer.reducer