import { extendTheme } from '@chakra-ui/react';
import { MultiSelectTheme } from 'chakra-multiselect';

const hacklabColors = {
    'h-primary': '#5D5FEF',
    'h-black': '#0E1427',
    dark: '#0E1427',
    dark2: '#1F2937',
    primary: '#5D5FEF',
    link: "#2563EB",
    muted: '#475569',
    'grey-100': '#E5E7EB',
}

const hacklabTheme = extendTheme({
    colors: hacklabColors,
    fonts: {
        heading: 'Poppins-Regular',
        body: 'Inter-Regular',
        // Define more fonts here
    },
    components: {
        MultiSelect: MultiSelectTheme
    }
    // Add more theme configurations as needed
});


export { hacklabTheme, hacklabColors };