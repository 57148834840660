function FooterLeft() {
  return (
    <section className="entire-footerleft m-6">
      <img
        src="assets/svgs/hacklab2.svg"
        className="h-[50px] mt-2 mb-4"
        alt=""
      />

      <h2 className="font-sans">© 2022 Hacklab. All rights reserved</h2>
    </section>
  );
}

export default FooterLeft;
