
import { ErrorFn, GithubAuthProvider, GoogleAuthProvider, NextOrObserver, User, createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import firebaseApp from './config';

export const FirebaseAuth = getAuth(firebaseApp,);
// connectAuthEmulator(FirebaseAuth, "http://127.0.0.1:9099");

export async function googleAuth() {
    let provider = new GoogleAuthProvider();
    provider.addScope('email')
    provider.addScope('openid')
    provider.addScope('profile')

    // x.addScope('email')
    // x.addScope('username')

    return signInWithPopup(FirebaseAuth, provider)
}


export function githubAuth() {
    let x = new GithubAuthProvider();
    x.addScope('read:user')
    x.addScope('user:email')
    x.addScope('user:follow')

    return signInWithPopup(FirebaseAuth, x);
}

export function signInWithEmail(email: string, password: string) {
    return signInWithEmailAndPassword(FirebaseAuth, email, password);
}

export function signUpWithEmail(email: string, password: string) {
    return createUserWithEmailAndPassword(FirebaseAuth, email, password);
}

export function onAuthChanges(next: NextOrObserver<User>, error?: ErrorFn | undefined) {
    return onAuthStateChanged(FirebaseAuth, next, error)
}