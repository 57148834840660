import { RootState, useAppDispatch } from "@/redux/app.store";
import { removeTeamMemberThunk } from "@/redux/slices/team/manageTeam";
import {
  Avatar,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { HackerRole } from "@utils/constants";
import { useState } from "react";
import {
  Clipboard,
  Clipboard2Check,
  Pencil,
  PersonX,
  ThreeDotsVertical,
} from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import NewTeamModal from "./NewTeamModal";

interface TeamSettingsModalProps {
  isOpen: boolean;
  onClose: any;
}

function TeamSettingsModal(props: TeamSettingsModalProps) {
  const teamState = useSelector((state: RootState) => state.teamStore);
  const userState = useSelector((state: RootState) => state.authReducer);
  const editTeam = useDisclosure();
  const [users, setUsers] = useState(teamState.team?.members ?? []);
  const toast = useToast();
  const dispatch = useAppDispatch();

  const isAdmin = teamState.isAdmin;

  let roles = [
    {
      title: "Frontend",
      value: HackerRole.Frontend,
    },
    {
      title: "Backend",
      value: HackerRole.Backend,
    },
    {
      title: "UI",
      value: HackerRole.UI,
    },
    {
      title: "Analyst",
      value: HackerRole.Analyst,
    },
    {
      title: "Other",
      value: HackerRole.Analyst,
    },
  ];

  // function onToProfile() {}

  function copyLink() {
    const link = window.location.href + "?join=" + teamState.team?.id;
    navigator.clipboard.writeText(link);
    toast({
      description: link + " copied",
      icon: <Clipboard2Check />,
      status: "success",
    });
  }

  async function removeMember(uid: string) {
    try {
      await dispatch(
        removeTeamMemberThunk({ uid: uid, teamId: teamState.team!.id! })
      ).unwrap();
    } catch (error) {}
  }

  function onSubmit() {}

  return (
    <div>
      <NewTeamModal
        isOpen={editTeam.isOpen}
        onClose={editTeam.onClose}
        team={teamState.team}
      ></NewTeamModal>
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        isCentered
        size={"3xl"}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <div>
              <ModalCloseButton />
            </div>
            <h1 className="text-center mt-4 md:mt-6 text-[#1F2937] font-[Inter-Light] font-[600] text-[20px] tracking-[0.005em]">
              <div className="flex justify-center items-center gap-2">
                <small className="text-muted">Team Name: </small>
                <h1 className="text-xl font-bold">
                  {" "}
                  {teamState.team?.name} ({teamState.team?.alias})
                </h1>
                {isAdmin && (
                  <Button
                    onClick={() => {
                      props.onClose();
                      editTeam.onOpen();
                    }}
                    variant="ghost"
                  >
                    <Pencil />
                  </Button>
                )}
              </div>

              <div className="flex justify-center items-center gap-2">
                <small className="text-muted text-left">Team Id: </small>
                <p className="text-lg font-semibold"> {teamState.team?.id}</p>
              </div>
            </h1>
          </ModalHeader>

          <ModalBody>
            <div className="space-y-4 mb-5">
              <div className="text-center ">
                <Avatar size={"xl"} name={teamState.team?.name} className="" />

                {/* <p> 
                  {(teamState.team?.interests ?? [])?.map((v) => (
                    <Tag>{v}</Tag>
                  ))}
                </p> */}
                <div className="flex justify-start flex-col">
                  <small className="text-muted text-left font-bold">
                    Team Bio
                  </small>
                  <p className="text-justify"> {teamState.team?.description}</p>
                </div>
              </div>

              <div className="px-3 py-5 bg-gray-50 rounded-md  mt-4 md:mt-6 space-y-2">
                <h2 className="text-[#6B7280] font-semibold text-center tracking-[0.005em]">
                  Manage Members in your team
                </h2>
                {users.map((v) => (
                  <div
                    key={v.uid}
                    className="w-[100%] h-[60px] border-2 rounded-[10px] p-3 flex flex-row justify-between items-center"
                  >
                    <div className="flex flex-row justify-between items-center w-[60%] mr-4">
                      <Avatar size={"sm"} name={v.name} src={v.image} />

                      <h2 className="font-[600] text-[#1F2937] tracking-[0.005em] px-3">
                        <Editable
                          isDisabled={!isAdmin}
                          className="text-left"
                          defaultValue={v.name}
                          contentEditable={v.uid === userState.userInfo?.uid}
                          onChange={(newVale) => {
                            let newList = [...users];
                            let index = newList.findIndex(
                              (mem) => mem.uid === v.uid
                            );
                            if (index) {
                              newList[index].name = newVale;
                              setUsers(newList);
                            }
                          }}
                        >
                          <EditablePreview />
                          <EditableInput />
                        </Editable>
                      </h2>

                      <Menu>
                        <MenuButton as={Button} variant={"ghost"}>
                          <ThreeDotsVertical />
                        </MenuButton>
                        <MenuList>
                          {/* <MenuItem
                          isDisabled={!isAdmin}
                          onClick={onToProfile}
                          icon={<Person />}
                        >
                          Profile
                        </MenuItem> */}
                          <MenuItem
                            isDisabled={!isAdmin}
                            onClick={() => removeMember(v.uid)}
                            icon={<PersonX />}
                          >
                            Remove team member
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </div>
                    <Select
                      isDisabled={!isAdmin}
                      placeholder="Select Role"
                      onChange={(newVale) => {
                        let newList = [...users];
                        let index = newList.findIndex(
                          (mem) => mem.uid === v.uid
                        );
                        if (index) {
                          newList[index].role = newVale.target.value;
                          setUsers(newList);
                        }
                      }}
                    >
                      {roles.map((v) => (
                        <option key={v.value} value={v.value}>
                          {v.title}
                        </option>
                      ))}
                    </Select>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="w-full text-center mt-4 md:mt-6">
              <Button
                className="mx-auto"
                variant={"ghost"}
                leftIcon={<PlusSquare />}
                onClick={addUser}
              >
                Add New Member
              </Button>
            </div> */}
            <div className="w-full text-center mt-4 md:mt-6">
              <Button
                className="mx-auto"
                variant={"ghost"}
                leftIcon={<Clipboard />}
                onClick={copyLink}
              >
                Copy joining link
              </Button>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              background={"white"}
              border={"gray"}
              mr={3}
              onClick={props.onClose}
            >
              Cancel
            </Button>
            {isAdmin && (
              <Button variant={"solid"} onClick={onSubmit}>
                Update team members
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <section className="entire-modal-wrapper">
        <div>
          <section className="inner-modal">
            <div className="members-wrapper flex justify-center flex-col">
             
            </div>
          </section>
        </div>
      </section> */}
    </div>
  );
}

TeamSettingsModal.propTypes = {};

export default TeamSettingsModal;
