import { ChakraProvider } from "@chakra-ui/react";
import "@styles/globals.scss";
import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import { ProSidebarProvider } from "react-pro-sidebar";
import { Provider } from "react-redux";
import { FirebaseAppProvider } from "reactfire";
import App from "./App";
import { AuthMethod } from "./interfaces/user";
import { appStore } from "./redux/app.store";
import { appAuthThunk } from "./redux/slices/auth";
import reportWebVitals from "./reportWebVitals";
import { FirebaseConfig } from "./services/config";
import { hacklabTheme } from "./utils/theme";

appStore.dispatch(
  appAuthThunk({
    method: AuthMethod.Listen,
    payload: null,
  })
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={FirebaseConfig}>
      <Provider store={appStore}>
        <ProSidebarProvider>
          <CookiesProvider>
            <ChakraProvider theme={hacklabTheme}>{<App />}</ChakraProvider>
          </CookiesProvider>
        </ProSidebarProvider>
      </Provider>
    </FirebaseAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
