import { Hacker } from "@/interfaces/user";
import { RootState } from "@/redux/app.store";
import { Firestore } from "@/services/firebase";
import { COUNTRIES, DEVELOPMENT, INTERESTS } from "@/utils/data";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { MultiSelect } from "chakra-multiselect";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
export interface ProfileModalProps {
  isOpen: boolean;
  onClose: any;
  userInfo?: Hacker;
}
function EditProfileModal({ isOpen, onClose, userInfo }: ProfileModalProps) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const fireUser = useSelector(
    (state: RootState) => state.authReducer
  ).userInfo;

  //   const [snapshot, loading, error] = useDocument(
  //     doc(Firestore, "users", authState?.uid ?? "random")
  //   );

  //   let userInfo = snapshot?.data();
  const { control, register, handleSubmit } = useForm<Partial<Hacker>>({
    values: {
      country: userInfo?.country ?? "",
      institution: userInfo?.institution ?? "",
      bio: userInfo?.bio ?? "",
      github: userInfo?.github ?? "",
      twitter: userInfo?.twitter ?? "",
      linkedin: userInfo?.linkedin ?? "",
      website: userInfo?.website ?? "",
      stack: userInfo?.stack ?? [],
      languages: userInfo?.languages ?? [],
      codeName: userInfo?.codeName ?? "",
      displayName: userInfo?.displayName ?? "",
      phoneNumber: userInfo?.phoneNumber ?? "",
      interests: userInfo?.interests ?? [],
    },
  });

  async function onSubmit($event: Partial<Hacker>) {
    if (!fireUser?.uid) {
      toast({ description: "Failed to update your profile", status: "error" });
    }

    if (
      $event.phoneNumber !== "" &&
      !isValidPhoneNumber($event.phoneNumber ?? "")
    ) {
      toast({ description: "Invalid phone number" });
      return;
    }

    try {
      setLoading(true);
      let path = doc(Firestore, "users", fireUser!.uid);

      await updateDoc(path, {
        ...($event as any),
        updatedOn: serverTimestamp(),
      });
      toast({ description: "Profile updated", status: "success" });
    } catch (error) {
      toast({
        description: "Failed to update your profile",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      size={"2xl"}
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader>Update your hacker profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className=" flex flex-col md:grid md:grid-cols-2 gap-4 group-form-control ">
              <Controller
                name="displayName"
                control={control}
                rules={{ required: true }}
                render={({ field, formState, fieldState }) => (
                  <FormControl
                    isInvalid={
                      formState.errors.displayName && fieldState.isTouched
                    }
                  >
                    <FormLabel>User name</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                name="codeName"
                control={control}
                rules={{ required: true }}
                render={({ field, formState, fieldState }) => (
                  <FormControl
                    isInvalid={
                      formState.errors.codeName && fieldState.isTouched
                    }
                  >
                    <FormLabel>Hacker name</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                name="phoneNumber"
                control={control}
                rules={{ required: true }}
                render={({ field, formState, fieldState }) => (
                  <FormControl
                    isInvalid={formState.errors.country && fieldState.isTouched}
                  >
                    <FormLabel>Phone number (eg:+XXX 000000)</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                name="institution"
                control={control}
                rules={{ required: true }}
                render={({ field, formState, fieldState }) => (
                  <FormControl
                    isInvalid={
                      formState.errors.institution && fieldState.isTouched
                    }
                  >
                    <FormLabel>Institution</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                name="country"
                control={control}
                rules={{ required: true }}
                render={({ field, formState, fieldState }) => (
                  <FormControl
                    isInvalid={formState.errors.country && fieldState.isTouched}
                  >
                    <FormLabel>Country</FormLabel>
                    <MultiSelect
                      single
                      {...field}
                      options={COUNTRIES.map((v) => ({
                        value: v,
                        label: v,
                      }))}
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                name="interests"
                control={control}
                rules={{ required: true }}
                render={({ field, formState, fieldState }) => (
                  <FormControl
                    isInvalid={formState.errors.stack && fieldState.isTouched}
                  >
                    <FormLabel>Interests</FormLabel>
                    <MultiSelect
                      {...field}
                      create
                      options={INTERESTS.map((v) => ({
                        value: v.name,
                        label: v.name,
                      }))}
                    />

                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                name="stack"
                control={control}
                rules={{ required: true }}
                render={({ field, formState, fieldState }) => (
                  <FormControl
                    isInvalid={formState.errors.stack && fieldState.isTouched}
                  >
                    <FormLabel>Stacks / Frameworks</FormLabel>
                    <MultiSelect
                      {...field}
                      create
                      options={DEVELOPMENT.frameworks.map((v) => ({
                        value: v,
                        label: v,
                      }))}
                    />

                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                name="languages"
                control={control}
                render={({ field, formState, fieldState }) => (
                  <FormControl
                    isInvalid={
                      formState.errors.languages && fieldState.isTouched
                    }
                  >
                    <FormLabel>Programming Languages </FormLabel>
                    <MultiSelect
                      {...field}
                      create
                      options={DEVELOPMENT.programming_languages.map((v) => ({
                        value: v,
                        label: v,
                      }))}
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                name="bio"
                control={control}
                render={({ field, formState, fieldState }) => (
                  <FormControl
                    className="col-span-2"
                    isInvalid={formState.errors.bio && fieldState.isTouched}
                  >
                    <FormLabel>About you</FormLabel>
                    <Textarea {...field} />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                name="linkedin"
                control={control}
                render={({ field, formState, fieldState }) => (
                  <FormControl
                    isInvalid={
                      formState.errors.linkedin && fieldState.isTouched
                    }
                  >
                    <FormLabel>Linkedin</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                name="twitter"
                control={control}
                render={({ field, formState, fieldState }) => (
                  <FormControl
                    isInvalid={formState.errors.twitter && fieldState.isTouched}
                  >
                    <FormLabel>Twitter</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                name="github"
                control={control}
                render={({ field, formState, fieldState }) => (
                  <FormControl
                    isInvalid={formState.errors.github && fieldState.isTouched}
                  >
                    <FormLabel>Github</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                name="website"
                control={control}
                render={({ field, formState, fieldState }) => (
                  <FormControl
                    isInvalid={formState.errors.website && fieldState.isTouched}
                  >
                    <FormLabel>Website</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </div>
          </ModalBody>

          <ModalFooter className="space-x-4">
            <Button variant={"ghost"} onClick={onClose}>
              Close
            </Button>
            {fireUser && (
              <Button
                colorScheme="blue"
                isLoading={loading}
                type="submit"
                variant="solid"
              >
                Save
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}

export default EditProfileModal;
