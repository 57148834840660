interface SectionTitleProps {
  title: string;
  children?: any;
}

function SectionTitle(props: SectionTitleProps) {
  return (
    <>
      <h2 className="font-sans font-[600] text-[20px] mb-2 md:mb-4">
        {props.title}
      </h2>
      {props.children}
    </>
  );
}

export default SectionTitle;
