"use client";

import ProjectSubmissionModal from "@/components/modals/ProjectSubmission";
import Loading from "@/components/shared/Loading";
import { Project } from "@/interfaces/project";
import { RootState } from "@/redux/app.store";
import { Firestore } from "@/services/firebase";
import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import EmptyDashboard from "@components/shared/EmptyDashboard";
import ProjectItem from "@components/shared/ProjectItem";
import SectionTitle from "@components/shared/SectionTitle";
import { collection, query, where } from "firebase/firestore";
import { Plus } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useFirestoreCollection } from "reactfire";

function Projects() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const team = useSelector((state: RootState) => state.teamStore).team;
  const q = query(
    collection(Firestore, "projects"),
    where("teamId", "==", team?.id)
  );

  const { data, status, error } = useFirestoreCollection(q);

  const toast = useToast();

  let projects: Project[] = (data?.docs.map((v) => v.data()) as any[]) ?? [];
  let drafts = projects.filter((x) => x.status === "draft");
  let completed = projects.filter((v) => v.status === "completed");
  let completedReview = projects.filter((v) => v.status === "reviewCompleted");

  function openModal() {
    onOpen();
  }
  return (
    <>
      <ProjectSubmissionModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
      ></ProjectSubmissionModal>

      <SectionTitle title="Projects Submitted for Review" />
      <div className="flex w-full md:hidden justify-end ">
        <Button
          onClick={openModal}
          className="ml-auto mb-2 "
          size={"lg"}
          leftIcon={<Plus />}
        >
          Submit new project
        </Button>
      </div>
      <Tabs>
        <TabList>
          <Tab>All</Tab>
          <Tab>Draft</Tab>
          <Tab>Pending Reviews</Tab>
          <Tab>Reviewed</Tab>

          <div className="ml-auto hidden md:block w-fit">
            <Button
              onClick={openModal}
              className=" mb-2 "
              size={"lg"}
              leftIcon={<Plus />}
            >
              Submit new project
            </Button>
          </div>
        </TabList>

        {status === "loading" ? (
          <Loading />
        ) : (
          <TabPanels>
            <TabPanel>
              {projects.length === 0 ? (
                <EmptyDashboard />
              ) : (
                <div className="space-y-4">
                  {projects.map((v) => (
                    <ProjectItem key={v.id} project={v} />
                  ))}
                </div>
              )}
            </TabPanel>

            <TabPanel>
              {drafts.length === 0 ? (
                <EmptyDashboard />
              ) : (
                <div className="space-y-4">
                  {drafts.map((v) => (
                    <ProjectItem key={v.id} project={v} />
                  ))}
                </div>
              )}
            </TabPanel>

            <TabPanel>
              {completed.length === 0 ? (
                <EmptyDashboard />
              ) : (
                <div className="space-y-4">
                  {completed.map((v) => (
                    <ProjectItem key={v.id} project={v} />
                  ))}
                </div>
              )}
            </TabPanel>

            <TabPanel>
              {completedReview.length === 0 ? (
                <EmptyDashboard />
              ) : (
                <div className="space-y-4">
                  {completedReview.map((v) => (
                    <ProjectItem key={v.id} project={v} />
                  ))}
                </div>
              )}
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>
    </>
  );
}

export default Projects;
