
import { AppState } from '@/interfaces/base';
import { AppAuthState, AuthMethod, AuthType } from '@/interfaces/user';
import { FirebaseAuth, githubAuth, googleAuth, onAuthChanges, signInWithEmail, signUpWithEmail } from '@/services/auth';
import { Firestore } from '@/services/firebase';
import { AppStorage } from '@/services/storage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthError, User, signOut, updateProfile } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';



const initialState: AppAuthState = {
    userInfo: null,
    state: AppState.idle,
    error: null,
};

export const appAuthThunk = createAsyncThunk('auth/auth', async (data: { type?: AuthType, method: AuthMethod, payload: any },
    { dispatch, getState }) => {
    switch (data.method) {
        case AuthMethod.Listen:
            const user = AppStorage.getData<any>(AppStorage.user);

            if (user) {

                // return JSON.parse(user)
                const x: AppAuthState = {
                    error: null,
                    state: AppState.idle,
                    userInfo: user
                }
                return x
            }

            onAuthChanges((user) => {
                const x: AppAuthState = {
                    error: null,
                    state: AppState.idle,
                    userInfo: user
                }
                return x
            })
            break;
        case AuthMethod.Google:
            try {

                const res = await googleAuth();
                // console.log(res.user.metadata)
                if (res.operationType) {

                    await dispatch(createUserThunk(res.user)).unwrap()

                    const x: AppAuthState = {
                        error: null,
                        state: AppState.idle,
                        userInfo: res.user
                    }
                    return x;
                }
            } catch (error) {
                // console.log(error)
                throw (error as AuthError).message;
            }
            break;
        case AuthMethod.Github:
            try {

                const res = await githubAuth();

                if (res.user) {

                    await dispatch(createUserThunk(res.user)).unwrap()

                    const x: AppAuthState = {
                        error: null,
                        state: AppState.idle,
                        userInfo: res.user
                    }
                    return x;
                }
            } catch (error) {
                // console.log(error)
                throw (error as AuthError).message;
            }
            break;
        case AuthMethod.Email:
            if (data.type === AuthType.SignIn) {
                try {

                    const res = await signInWithEmail(data.payload.email, data.payload.password);

                    if (res.user) {
                        const x: AppAuthState = {
                            error: null,
                            state: AppState.idle,
                            userInfo: res.user
                        }
                        return x;
                    }
                } catch (error) {
                    throw (error as AuthError).message;
                }
            } else {
                try {

                    const res = await signUpWithEmail(data.payload.email, data.payload.password);

                    if (res.user) {
                        if (data.payload.username)
                            updateProfile(res.user, {
                                displayName: data.payload.username,
                            })

                        await dispatch(createUserThunk({ ...res.user, displayName: data.payload.username, })).unwrap()
                        const x: AppAuthState = {
                            error: null,
                            state: AppState.idle,
                            userInfo: { ...res.user, displayName: data.payload.username, }
                        }
                        return x;
                    }
                } catch (error) {
                    throw (error as AuthError).message;
                }
            }
            break;
        default:
            break;
    }
})


const createUserThunk = createAsyncThunk('auth/createUser', async (payload: User) => {
    let docPath = doc(Firestore, 'users', payload.uid);
    const res = await setDoc(docPath, {
        displayName: payload.displayName,
        emailVerified: payload.emailVerified,
        email: payload.email,

        uid: payload.uid,
        providerId: payload.providerId,
        photoURL: payload.photoURL,
        phoneNumber: payload.phoneNumber,
        providerData: payload.providerData,
        interests: [],
        codeName: null,
        createdAt: Date.parse(payload.metadata.creationTime ?? new Date().toISOString())

    });
    return res;
})

export const signOutThunk = createAsyncThunk('auth/signOut', async (payload, { dispatch }) => {
    dispatch(authenticated(initialState))
    AppStorage.delete(AppStorage.user)
    await signOut(FirebaseAuth);

})

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        authenticated: (state, action) => {
            state.error = action.payload.error;
            state.userInfo = action.payload.userInfo;
            state.state = action.payload.state;
        }
        // googleSignIn: (state, action) => {
        //     googleAuth().then(v => {
        //         // state = { role: '', ...v.user }
        //     })
        // },
        // githubSignIn: (state, action) => {
        //     githubAuth().then(v => {
        //         ///
        //     })
        // },
        // emailPassword: (state, action) => {
        //     if (action.type === 'signUp') {
        //         signInWithEmail(action.payload.email, action.payload.password).then(v => {
        //             // state = 
        //         })
        //     }
        // }
    },
    extraReducers(builder) {
        builder.addCase(appAuthThunk.fulfilled, (state, { payload, }) => {
            if (payload) {
                // console.log(payload)
                state.error = null;
                state.state = AppState.success;
                state.userInfo = payload!.userInfo;
                AppStorage.setData(AppStorage.user, payload.userInfo)
            } else {
                // throw Error('User not found')
            }
        })
        builder.addCase(appAuthThunk.rejected, (state, { payload, error }) => {
            state.userInfo = payload as any;
            state.state = AppState.error;
            state.error = error.message ?? "";
            // Cookies.set()

        })
        builder.addCase(appAuthThunk.pending, (state) => {
            state.state = AppState.loading
            state.userInfo = null;
            state.error = '';
        })

    },
})



export const { authenticated } = authSlice.actions;

export const AuthReducer = authSlice.reducer;