import certificate from "@assets/svgs/certificate.svg";
import gold from "@assets/svgs/firststargold.svg";
import bullet from "@assets/svgs/globe-bullet.svg";
import ring from "@assets/svgs/purple-ring.svg";
import silver from "@assets/svgs/secondstarsilver.svg";
import bronze from "@assets/svgs/thirdstarbronze.svg";
import styles from "@styles/Dashboard.module.scss";
import React from "react";
import { PeopleFill, PersonPlus } from "react-bootstrap-icons";
import { PrizesHackathon, TimelineHackathon } from "../../interfaces/timeline";
import FooterMiddle from "./FooterMiddle";
import Rules from "./Rules";

function AboutHackathon() {
  const map: TimelineHackathon[] = [
    { date: "8 January", text: "Introduction", time: "09:30 - 12:30" },
    { date: "9 January", text: "Start", time: "07:15 - 09:45" },
    { date: "9 January", text: "First Draft", time: "07:15 - 09:45" },
    { date: "9 January", text: "Judging", time: "09:30 - 12:30" },
    { date: "8 November", text: "Final Judging and Prize", time: "" },
    { date: "8 November", text: "Presentation", time: "07:00 - 13:00" },
    { date: "8 November", text: "Start", time: "13:00 - 14:40" },
    { date: "8 November", text: "Next Stage", time: "13:00 - 14:40" },
  ];

  const prizeBox: PrizesHackathon[] = [
    {
      image: gold,
      position: "Winner",
      prizeMoney: "$35,000",
      color: "#5D5FEF",
    },
    {
      image: silver,
      position: "First Runner Up",
      prizeMoney: "$25,000",
      color: "#7879F1",
    },
    {
      image: bronze,
      position: "Second Runner Up",
      prizeMoney: "$10,000",
      color: "#A5A6F6",
    },
  ];

  const judging = [
    `Anyone eligible to compete in the contest is welcomed and
            encouraged to participate. However, you can only be an IBM zStudent
            contest winner two times total, consecutive or not.`,
    `Two themes are defined for the Contest; you must submit a project
            that is in line with the theme(s).`,
    `You will be required to create a profile on the IBM Z Xplore
            learning platform - this will provide you with appropriate access to
            an IBM zSystems, through a zSystem login profile. The contest Drops
            will be provided on the IBM Z Xplore learning platform.`,
  ];

  return (
    <div>
      <section className="entire-about-wrapper space-y-8 w-[100%]">
        <section>
          <AboutHackathonTitle title="About Hackathon" />

          <div className="participants-team-info text-[25px] font-semibold flex flex-col md:flex-row items-center md:gap-8 justify-left space-y-4 md:space-y-0">
            <div className="left flex flex-row items-center gap-4">
              <PersonPlus />
              <h2 className="">
                Allowed Team Size : <span>5</span>
              </h2>
            </div>

            <div className="right flex flex-row items-center gap-4">
              <PeopleFill />
              <h2 className=" ">
                <span>900</span> Participants
              </h2>
            </div>
          </div>

          <div className="description  p-3">
            <p className="text-[20px] text-[#6b7280] font-sans text-justify">
              This hackathon is the lkjpoavilepoaidv ;lkjaldsoieun ien jspoid
              vlk . In Zambia koivon oi ldooin ijgoieh o oidi on oeiohf v oihdo
              ad oig poigvehispo v oisd fai s ovoaseihio dh oeonv this is truly
              a great experience for anyone seeking to solve world problems,
              o9eho o
              <br />
              <br />
              <span className="pt-6">
                This section is to talk about the hackathon.
              </span>
              <br />
              <br />
              <span className="text-[#2f80ed]">
                The Timeline for the Hackathon
              </span>
            </p>
          </div>

          <div className="timeline-map flex flex-row flex-wrap gap-4 ">
            {map.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="container  bg-white shadow-lg shadow-[#00000026] w-[170px] md:w-[200px] p-3 md:p-[20px]  h-[180px] rounded-[20px] ">
                    <h2 className="font-[Roboto-Regular]">{item.date}</h2>
                    <img src={ring} className="w-[40px] h-[40px] mt-2" alt="" />
                    <h2 className="font-[Roboto-Medium] text-[20px]">
                      {item.text}
                    </h2>

                    <h2 className="font-[Roboto-Light] text-[20px] font-[400]">
                      {item.time}
                    </h2>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </section>

        <section className="hackathon-themes">
          <AboutHackathonTitle title="Hackathon Themes" />
          <div className="space-y-4">
            <div className="pointOne flex flex-row items-start  justify-center">
              <img src={bullet} className="ml-3" alt="" />

              <div className="text ml-4 md:ml-10 space-y-2">
                <h2 className="text-[#5D5FEF] font-sans text-xl font-[600] tracking-[0.005em] ">
                  Sustainability
                </h2>
                <h2 className="text-[#6B7280] font-sans text-lg">
                  First theme the hackathon will tackle. The world is fast
                  evolving and we as Africans need to put measures in place to
                  ensure its sustainability. Basically explain the theme the
                  hackathon is supposed to follow.
                </h2>
              </div>
            </div>

            <div className="pointOne flex flex-row items-start  justify-center">
              <img src={bullet} className="ml-3" alt="" />

              <div className="text ml-4 md:ml-10 space-y-2">
                <h2 className="text-[#5D5FEF] font-sans text-xl font-[600] tracking-[0.005em] justify-center">
                  Innovation
                </h2>
                <h2 className="text-[#6B7280] font-sans text-lg">
                  First theme the hackathon will tackle. The world is fast
                  evolving and we as Africans need to put measures in place to
                  ensure its sustainability. Basically explain the theme the
                  hackathon is supposed to follow.
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section className="prizes-wrapper space-y-4 ">
          <AboutHackathonTitle title="Prizes" />

          <h2 className="font-sans text-[#6B7280] text-lg ">
            Over $70,000.00 Worth of prize up for grabs
          </h2>

          <div className="flex flex-row flex-wrap  items-center gap-4">
            {prizeBox.map((info, index) => {
              return (
                <div
                  className="prizes-container bg-[#5D5FEF] w-[180px] md:w-[200px] h-[180px] rounded-[16px]"
                  key={index}
                >
                  <h2 className="font-sans text-center relative top-3 text-white">
                    {info.position}
                  </h2>

                  <div className="inner-prizes m-4 mt-8 mb-0 ">
                    <img
                      src={info.image}
                      width={30}
                      height={30}
                      alt={info.position}
                    />
                    <h2 className="font-sans relative top-3 text-white text-[30px]">
                      {info.prizeMoney}
                    </h2>
                    <h2 className="font-sans relative top-3 text-white">
                      Sponsored Rewards
                    </h2>
                  </div>
                </div>
              );
            })}
          </div>

          <h2 className="font-sans text-[#6B7280] ">
            All participants get certified Hacklab Certificates to boost resume
            and much more opportunities to connect with big tech companies for
            internships, apprenticeships and even employment.
          </h2>

          <img width={300} height={200} src={certificate} alt="" />
        </section>

        <section>
          <AboutHackathonTitle title="Rules" />
          <Rules />
        </section>

        <section className="criteria-wrapper  ">
          <AboutHackathonTitle title="Judging Criteria" />
          <ol className="space-y-3 list-decimal">
            {judging.map((item, index) => (
              <li key={item} className="text-[#6B7280] font-sans text-lg ">
                {" "}
                {item}
              </li>
            ))}
          </ol>
        </section>

        <section className="merch-wrapper ">
          <AboutHackathonTitle title="Hacker's Merchandise" />

          <ol className="space-y-5 list-decimal">
            <li className="space-y-3">
              <h2 className="  text-[#6B7280] font-sans ">
                These are merchandise that all participants will receive upon
                completion or during the hackathon.
              </h2>
              <div className="grey-rectangle w-[95%] h-[250px] bg-[#D9D9D9] rounded-[12px]"></div>
            </li>

            <li className="space-y-3">
              <h2 className="text-[#6B7280] font-sans ">
                These are merchandise that participants who enter the final
                stages will receive
              </h2>

              <div className="sub-merch-div flex gap-4  flex-row flex-wrap">
                <div className="submerch-1">
                  <div className="grey-rectangle w-[180px] h-[180px] bg-[#D9D9D9] rounded-[12px]"></div>

                  <h2 className=" mt-2 font-sans">Hackers Beanie</h2>
                </div>

                <div className="submerch-2">
                  <div className="grey-rectangle   w-[180px] h-[180px] bg-[#D9D9D9] rounded-[12px]"></div>
                  <h2 className="  mt-2 font-sans">Hackers Backpack</h2>
                </div>

                <div className="submerch-3">
                  <div className="grey-rectangle w-[180px] h-[180px] bg-[#D9D9D9] rounded-[12px]"></div>
                  <h2 className="  mt-2 font-sans">Hacklab Sticker</h2>
                </div>
              </div>
            </li>
          </ol>
        </section>

        <section className="mt-10">
          <FooterMiddle />
        </section>
      </section>
    </div>
  );
}

function AboutHackathonTitle(props: { title: string }) {
  return (
    <div>
      <div className="about-header flex flex-row items-center py-2">
        <h2 className={styles.sectionHead}>{props.title}</h2>
        <div className="divider flex-grow h-1 bg-[#0E1427]"></div>
      </div>
    </div>
  );
}

export default AboutHackathon;
