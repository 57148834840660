import oracle from "@assets/svgs/oracle.svg";
import { Button } from "@chakra-ui/react";
import { ArrowRight } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";

import { PARTNERS_PAGE } from "@/utils/constants";
import dreamoval from "@assets/svgs/dreamoval.svg";
import ibm from "@assets/svgs/ibm.svg";
import impacthub from "@assets/svgs/impacthub.svg";
import twitter from "@assets/svgs/twitter-bird.svg";
import vodafone from "@assets/svgs/vodafone.svg";

function Partners(props: any) {
  let partners = [
    "/assets/svgs/oracle.svg",
    "/assets/svgs/twitter-bird.svg",
    "/assets/svgs/dreamoval.svg",
    "/assets/svgs/vodafone.svg",
    "/assets/svgs/impacthub.svg",
  ];
  return (
    <div>
      <section className="entire-partners bg-[#F5F4F7] w-[100%] h-[100px] flex flex-row justify-evenly items-center space-x-8 md:space-x-0 overflow-x-auto px-5">
        <img src={ibm} className="w-[70px] h-[70px]" alt="" />

        <img src={oracle} className="w-[150px] h-[100px]" alt="" />

        <img src={twitter} className="w-[50px] h-[50px]" alt="" />

        <img src={dreamoval} className="w-[150px] h-[100px]" alt="" />

        <img src={vodafone} className="w-[150px] h-[100px]" alt="" />

        <img src={impacthub} className="w-[50px] h-[50px]" alt="" />

        <div className="partners-link flex flex-row">
          <NavLink to={PARTNERS_PAGE} target="_blank">
            <Button variant={"ghost"} rightIcon={<ArrowRight />}>
              See All Partners
            </Button>
          </NavLink>
        </div>
      </section>
    </div>
  );
}

Partners.propTypes = {};

export default Partners;
