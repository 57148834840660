import { Project } from "@/interfaces/project";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import FsLightbox from "fslightbox-react";
import moment from "moment";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import ProjectSubmissionModal from "../modals/ProjectSubmission";

export interface ProjectItemProps {
  project: Project;
}

function ProjectItem({ project }: ProjectItemProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [toggler, setToggler] = useState(false);

  return (
    <div>
      <ProjectSubmissionModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        draft={project}
      ></ProjectSubmissionModal>

      <FsLightbox
        toggler={toggler}
        sources={project.screenshots}
        type="image"
      />

      <section className="entire-review-wrapper flex flex-row items-center">
        <div className="inner-wrapper p-4 border-2 border-[#EEECEC] rounded-2xl w-[800px]">
          <div className="upper-section border-b-2 border-b-[#EEECEC] flex flex-col ">
            <div className="p-2 flex justify-between">
              <h2 className="font-sans">{project.name}</h2>

              {project.status === "draft" && (
                <Button onClick={() => onOpen()} variant={"link"}>
                  Continue Edit
                </Button>
              )}
            </div>

            <div className="upper-lower-side flex flex-row items-center justify-between">
              <h2 className="p-2 font-sans font-bold">Files Description</h2>
              {project.reviewedOn ? (
                <>
                  <h2 className="p-2 font-sans">
                    Reviewed on {project.reviewedOn}
                  </h2>
                </>
              ) : (
                <h2 className="p-2 font-sans">
                  {moment(project.createdAt.toDate()).fromNow()}
                </h2>
              )}
            </div>
          </div>

          <div className="upper-section grid grid-cols-3">
            <div className="col-span-1">
              <h2 className="p-2 font-sans font-semibold">Run Code</h2>
              <h2 className="p-2 font-sans  font-semibold">Presentation</h2>
              <h2 className="p-2 font-sans  font-semibold">Screenshots</h2>
            </div>

            <div className="col-span-1">
              <h2 className="p-2 font-sans">
                <a
                  className="text-blue-500"
                  href={project.demoLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  demo link
                </a>
              </h2>

              <h2 className="p-2 font-sans">
                <a
                  className="text-blue-500"
                  href={project.presentation}
                  target="_blank"
                  rel="noreferrer"
                >
                  presentation
                </a>
              </h2>

              <button
                className="p-2 text-blue-500"
                onClick={() => setToggler(!toggler)}
              >
                View all
              </button>
            </div>

            <div className=" text-center col-span-1">
              <h2 className="p-2 font-sans space-x-2 text-right">
                {project.reviewedOn === undefined ? (
                  <p className="text-red-500 uppercase">{project.status}</p>
                ) : (
                  <>
                    <span className="text-h-primary font-sans font-bold">
                      {project.score}/{project.totalScore}
                    </span>
                    <span>Reviewed by {project.numberOfJudges} judges</span>
                  </>
                )}
              </h2>
            </div>
          </div>
          <Accordion allowToggle>
            <AccordionItem>
              <AccordionButton className="w-full justify-between">
                <p className="font-bold">Useful Info</p>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                <div className="space-y-4 prose">
                  <div>
                    <h5 className="font-bold">Description</h5>
                    <p> {project.description}</p>
                  </div>
                  <div>
                    <h5 className="font-bold">Summary and How it works</h5>
                    <p className="text-h-black ">{project.summary}</p>
                  </div>
                </div>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>

        <div className="trash-container ml-3 p-2 border-2 w-[50px] h-[50px] rounded-[9px] border-[#DC0A0A] flex items-center justify-center">
          <Trash />
        </div>
      </section>
    </div>
  );
}

export default ProjectItem;
