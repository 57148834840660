import { Project } from "@/interfaces/project";
import { RootState } from "@/redux/app.store";
import { Firestore, uploadFile, uploadFiles } from "@/services/firebase";
import { socialUrls } from "@/utils/constants";
import { DEVELOPMENT } from "@/utils/data";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { MultiSelect } from "chakra-multiselect";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useRef, useState } from "react";
import { ArrowLeft, ChevronRight, FiletypePng } from "react-bootstrap-icons";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
interface ProjectSubmissionModalProp {
  isOpen: boolean;
  onClose: VoidFunction;
  onOpen: VoidFunction;
  draft?: Partial<Project>;
}
export default function ProjectSubmissionModal({
  isOpen,
  onOpen,
  onClose,
  draft,
}: ProjectSubmissionModalProp) {
  const team = useSelector((state: RootState) => state.teamStore).team;
  const user = useSelector((state: RootState) => state.authReducer).userInfo;
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [screenshots, setScreenshots] = useState<FileList | null>();
  const [presentation, setPresentations] = useState<FileList | null>();
  const screenshotInput = useRef<any>();
  const presentationInput = useRef<any>();

  const { control, formState, handleSubmit, getValues, setError, reset } =
    useForm<Partial<Project>>({
      values: draft,
    });

  const submit = (data: Partial<Project>) => {
    console.log(data);
    switch (currentTab) {
      case 0:
        if (
          !formState.errors.name &&
          !formState.errors.description &&
          !formState.errors.toolsUsed
        ) {
          setCurrentTab(currentTab + 1);
        } else {
          toast({
            description: "Please fill all required fields",
          });
        }

        break;
      case 1:
        setCurrentTab(currentTab + 1);
        break;
      case 2:
        saveAsComplete(data);
        break;
      default:
        break;
    }
  };

  const saveAsDraft = () => {
    let data = getValues();
    return saveProject(data, true);
  };

  const saveAsComplete = (data: Partial<Project>) => {
    return saveProject(data, false);
  };

  async function saveProject(data: Partial<Project>, isDraft: boolean) {
    try {
      if (!isDraft) {
        if (!screenshots) {
          toast({ description: "Please select a screenshot" });
          return;
        }
        if (!presentation) {
          toast({ description: "Please select a screenshot" });
          return;
        }
      }

      let projectId = v4();

      setLoading(true);

      if (screenshots)
        data.screenshots = await uploadFiles(screenshots!, `${projectId}`);
      if (presentation)
        data.presentation = await uploadFile(presentation[0]!, `${projectId}`);
      data.teamId = team?.id;
      data.createdAt = serverTimestamp() as any;
      data.updatedAt = serverTimestamp() as any;
      data.by = user?.uid;
      data.status = isDraft ? "draft" : "completed";

      let path = doc(Firestore, `projects/${projectId}`);
      await setDoc(path, data);
      reset();
      toast({
        status: "success",
        title: "Success",
        description: "Project submitted pending review",
      });
      onClose();
    } catch (error: any) {
      console.log(error);
      toast({
        status: "error",
        title: "Failed to submit project Please try again",
        description: error["message"],
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"lg"}>
      <DrawerOverlay />
      <form onSubmit={handleSubmit(submit)}>
        <DrawerContent style={{ margin: 0 }}>
          <DrawerCloseButton />
          <DrawerHeader className="mt-4 text-center">
            {currentTab === 0
              ? "Upload a Project"
              : currentTab === 1
              ? "Upload Files"
              : "Over view"}
          </DrawerHeader>

          <DrawerBody>
            <Tabs
              defaultIndex={0}
              index={currentTab}
              onChange={(index) => setCurrentTab(index)}
            >
              <TabList>
                <Tab isDisabled={currentTab !== 0}>
                  <span className="mr-3">Project Details</span> <ChevronRight />
                </Tab>
                <Tab isDisabled={currentTab !== 1}>
                  <span className="mr-3">Upload Files</span> <ChevronRight />
                </Tab>
                <Tab isDisabled={currentTab !== 2}>Summary</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <section className="entire-project-detail-form mt-10 space-y-4 md:space-y-8">
                    <Controller
                      control={control}
                      name={"name"}
                      render={({ field, fieldState }) => (
                        <FormControl isRequired isInvalid={fieldState.invalid}>
                          <FormLabel className="">Project title</FormLabel>
                          <Input
                            {...field}
                            className=""
                            type="text"
                            size={"lg"}
                          />
                          {fieldState.error && (
                            <FormErrorMessage>
                              {fieldState.error.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      )}
                    />

                    <Controller
                      control={control}
                      name="description"
                      render={({ field, fieldState }) => (
                        <FormControl isRequired isInvalid={fieldState.invalid}>
                          <FormLabel className="">
                            Project Description
                          </FormLabel>
                          <Textarea
                            {...field}
                            className=" h-[150px]"
                            size={"lg"}
                          />
                          <FormHelperText className="">
                            Write what your project is about
                          </FormHelperText>
                        </FormControl>
                      )}
                    />

                    <Controller
                      control={control}
                      name="toolsUsed"
                      render={({ field, fieldState }) => (
                        <FormControl isRequired isInvalid={fieldState.invalid}>
                          <FormLabel className=" ">Tools Used</FormLabel>
                          <MultiSelect
                            {...field}
                            create
                            options={[
                              ...DEVELOPMENT.programming_languages,
                              ...DEVELOPMENT.frameworks,
                              ...DEVELOPMENT.stacks,
                            ]
                              .sort()
                              .map((v) => ({
                                value: v,
                                label: v,
                              }))}
                          />
                          <FormHelperText className="">
                            What are the stack and tools you used
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </section>
                </TabPanel>
                <TabPanel>
                  <section className="entire-project-detail-form mt-10 space-y-4 md:space-y-8">
                    <Box>
                      <FormControl>
                        <FormLabel>Upload Snapshots of your Project</FormLabel>
                        <InputGroup>
                          <InputLeftAddon
                            bgColor={"primary"}
                            onClick={() => {
                              screenshotInput.current?.click();
                            }}
                            children={
                              <span className="flex flex-row items-center gap-1.5 text-white ">
                                <FiletypePng />
                                <span>Choose File</span>
                              </span>
                            }
                          />
                          <input
                            type="file"
                            ref={screenshotInput}
                            hidden
                            multiple
                            onChange={(e) => {
                              e.preventDefault();
                              setScreenshots(e.target.files);
                              ////
                            }}
                          />
                          <Input
                            className="outline-none"
                            type="text"
                            readOnly
                            value={
                              screenshots
                                ? screenshots?.length + " File(s) selected"
                                : undefined
                            }
                            placeholder="Upload screenshots of your project"
                          />
                        </InputGroup>
                        <FormHelperText className="text-label-info">
                          Upload a Max of three(3) snapshots (.jpg, .jpeg, .png)
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <Box>
                      <FormControl>
                        <FormLabel>Upload Presentation/Documents</FormLabel>
                        <InputGroup>
                          <InputLeftAddon
                            bgColor={"primary"}
                            onClick={() => {
                              presentationInput.current?.click();
                            }}
                            children={
                              <span className="flex flex-row items-center gap-1.5 text-white ">
                                <FiletypePng />
                                <span>Choose File</span>
                              </span>
                            }
                          />
                          <input
                            hidden
                            type="file"
                            ref={presentationInput}
                            onChange={(e) => {
                              e.preventDefault();
                              setPresentations(e.target.files);
                              ////
                            }}
                          />
                          <Input
                            className="outline-none"
                            type="text"
                            readOnly
                            value={
                              presentation
                                ? presentation?.length + " File selected"
                                : undefined
                            }
                            placeholder="Url link to video"
                          />
                        </InputGroup>
                        <FormHelperText className="text-label-info">
                          Upload a Max of three (3) Presentation (.odt, .ppt,
                          .pptx, .pdf etc.)
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <Box>
                      <Controller
                        control={control}
                        name="videoPresentation"
                        render={({ field, fieldState }) => (
                          <FormControl
                            isInvalid={fieldState.invalid}
                            label="Video Presentation"
                          >
                            <FormLabel>Video Presentation</FormLabel>
                            <InputGroup>
                              <InputLeftAddon children="Video link" />
                              <Input
                                {...field}
                                type="url"
                                placeholder="Url link to video"
                              />
                            </InputGroup>
                            <FormHelperText className="text-label-info">
                              Host your project on YouTube, Vimeo etc and post
                              here
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Box>

                    <Box>
                      <Controller
                        control={control}
                        name="demoLink"
                        render={({ field, fieldState }) => (
                          <FormControl>
                            <FormLabel className=" ">Demo Link</FormLabel>
                            <Input {...field} type="url" />
                            <FormHelperText className="">
                              Make sure that your the demo link is working
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Box>

                    <Box>
                      <Controller
                        control={control}
                        name="sourceCode"
                        render={({ field, fieldState }) => (
                          <FormControl>
                            <FormLabel className=" ">Source Code</FormLabel>
                            <Input {...field} type="url" />
                            <FormHelperText className="">
                              Share your public repository URL of GitHub,
                              BitBucket etc. You can invite
                              <span className="mx-1">
                                <a
                                  className="text-primary"
                                  href={socialUrls.githubProfile}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {socialUrls.githubUsername}
                                </a>
                              </span>
                              to collaborate if your repository is private.
                              (refer to this Handbook)
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Box>
                  </section>
                </TabPanel>
                <TabPanel>
                  <Controller
                    control={control}
                    name="summary"
                    render={({ field, fieldState }) => (
                      <FormControl>
                        <FormLabel className="">Special Instructions</FormLabel>
                        <Textarea
                          {...field}
                          className="h-[150px]"
                          size={"lg"}
                        />
                        <FormHelperText className="">
                          Is there a particular way to run your project?
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>

          <DrawerFooter className="space-x-3">
            {currentTab === 0 && (
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
            )}

            {currentTab > 0 && (
              <>
                <Button
                  variant={"outline"}
                  color={"primary"}
                  borderColor={"primary"}
                  borderWidth={"2"}
                  onClick={() => {
                    setCurrentTab(currentTab - 1);
                  }}
                  leftIcon={<ArrowLeft />}
                >
                  Back
                </Button>

                <Button
                  bgColor={"#DBEAFE"}
                  onClick={saveAsDraft}
                  className="text-primary hover:bg-opacity-95"
                >
                  Save as draft
                </Button>
              </>
            )}

            <Button isLoading={loading} type="submit">
              Continue
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
}
