import { Divider } from "@chakra-ui/react";
import FooterRight from "@components/shared/FooterRight";

function NotificationPanel(props: any) {
  return (
    <div className="w-full">
      <section className="entire-notif-wrapper  flex flex-col justify-center items-start border-2 rounded-[6px]">
        <div className="notif-header bg-[#F9FAFB] p-[20px] w-[100%] border-b-2">
          <h2 className="text-[#6B7280] text-[26px] align-left">
            Notifications
          </h2>
        </div>

        <div className="countdown-div">
          <img
            src="assets/svgs/countdown.svg"
            className="h-[100px] p-6 ml-0"
            alt=""
          />
        </div>

        {/* <div className="divider h-[2px] w-[85%] ml-6 mt-2 mb-2  bg-[#E5E7EB]"></div>
         */}

        <Divider />

        <p className="p-2 ml-5 mr-0 font-sans w-[90%] text-[20px] text-[#6B7280]">
          Are you going to participate as a team?
          <br />
          Then create your team <br /> and assign roles
        </p>

        <div className="link flex flex-row items-center justify-center p-2 ml-5">
          <h2 className="text-[#3B82F6] font-sans m-0 text-[20px] mr-3 tracking-[0.005em] font-semi-bold">
            Create Team
          </h2>
          <img src="assets/svgs/arr_right_blue.svg" alt="" />
        </div>

        {/* <div className="divider h-[2px] w-[85%] ml-6 mt-2 mb-2  bg-[#E5E7EB]"></div> */}
        <Divider />

        <p className="p-2 ml-5 mr-0 font-sans w-[90%] text-[20px] text-[#6B7280]">
          Nervousness can <br /> reduce quality of work. <br />
          Practice enough to <br /> make you comfortable <br /> before the
          Hackathon.
        </p>

        <div className="link flex flex-row items-center justify-center p-2 ml-5">
          <h2 className="text-[#3B82F6] font-sans m-0 text-[20px] mr-3 tracking-[0.005em] font-semi-bold">
            Practice Now
          </h2>
          <img src="assets/svgs/arr_right_blue.svg" alt="" />
        </div>

        {/* <div className="divider h-[2px] w-[85%] ml-6 mt-2 mb-2  bg-[#E5E7EB]"></div> */}
        <Divider />

        <h2 className="text-[#6B7280] font-sans p-2 ml-5 mb-[50px] text-[20px]">
          Joshua left the team
        </h2>
      </section>

      <section className="footer-rightside mt-[40px]">
        <FooterRight />
      </section>
    </div>
  );
}

NotificationPanel.propTypes = {};

export default NotificationPanel;
