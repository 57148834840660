"use client";

import { Divider } from "@chakra-ui/react";
import NotificationPanel from "@components/dashboard/Notification";
import Partners from "@components/dashboard/Partners";
import Timeline from "@components/dashboard/Timeline";
import TopBanner from "@components/dashboard/TopBanner";
import AboutHackathon from "@components/shared/AboutHackathon";
import AboutSideBarAccordion from "@components/shared/Accordion";

export default function DashboardIndex() {
  return (
    <section className="main-container  ">
      <TopBanner />
      <Partners />
      <Timeline />
      <Divider />
      <section className="about-section grid grid-cols-12 gap-4 md:gap-8  container mx-auto mt-4 md:mt-8  ">
        <div className="left hidden md:block md:col-span-3">
          <AboutSideBarAccordion />
        </div>

        <div className="col-span-12 px-4 md:px-0 md:col-span-6">
          <AboutHackathon />
        </div>

        <div className="right hidden md:block md:col-span-3 w-full justify-center">
          <NotificationPanel />
        </div>
      </section>
    </section>
  );
}
