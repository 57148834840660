import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import { DashCircle, Folder2Open, PlusCircle } from "react-bootstrap-icons";
import TeamSettings from "../dashboard/TeamSettings";

function AboutSideBarAccordion(props: any) {
  const titles = [
    {
      title: "About",
      route: "about",
      subs: [
        {
          title: "Hackathon Thtme(s)",
          route: "theme",
        },
        {
          title: "Prizes",
          route: "prizes",
        },
      ],
    },
    {
      title: "Rules",
      route: "about",
      subs: [
        {
          title: "Hackathon Theme(s)",
          route: "theme",
        },
        {
          title: "Prizes",
          route: "prizes",
        },
      ],
    },
  ];
  return (
    <div className="w-full">
      <section className="entire-accordion-wrapper ">
        {/* <!--First accordion - About--> */}

        <Accordion>
          {titles.map((item, index) => {
            return (
              <AccordionItem
                className="font-semibold text-base"
                key={item.title}
              >
                <h2>
                  <AccordionButton className="text-xl gap-6">
                    <PlusCircle size={"25px"} />
                    <Folder2Open size={"30px"} />
                    <Box as="span" flex="1" textAlign="left" padding={"3"}>
                      {item.title}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel marginLeft={"1rem"} pb={4}>
                  {item.subs.map((sub, index) => (
                    <div
                      key={sub.title}
                      className="first-option flex flex-row mb-2 items-center gap-4"
                    >
                      <a
                        href={`#${sub.route}`}
                        className="flex flex-row items-center gap-4 text-link"
                      >
                        <DashCircle />
                        <h2>{sub.title}</h2>
                      </a>
                    </div>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </section>

      <section className="left-side-bottom mt-4 md:mt-8">
        {/* <HackButton /> */}
        <div className="pr-5 pt-2 pb-2  mt-5">
          <TeamSettings showTitle={true} />
        </div>
      </section>
    </div>
  );
}

AboutSideBarAccordion.propTypes = {};

export default AboutSideBarAccordion;
