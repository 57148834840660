import { Spinner } from "@chakra-ui/react";

function AppSpinner() {
  return (
    <div className="w-fit mx-auto">
      <Spinner />
    </div>
  );
}

export default AppSpinner;
