"use client";

import JoinTeamModal from "@/components/modals/JoinTeamModal";
import AppSpinner from "@/components/shared/AppSpinner";
import JoinTeam from "@/components/shared/JoinTeam";
import { AppState } from "@/interfaces/base";
import { RootState, useAppDispatch } from "@/redux/app.store";
import { getTeamThunk } from "@/redux/slices/team/getTeam";
import { useDisclosure } from "@chakra-ui/react";
import TeamsSideBar from "@components/dashboard/TeamsSideBar";
import { HacklabHeader } from "@components/shared/Header";
import { useEffect, useState } from "react";
import { Sidebar } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

function TeamDashboard() {
  const navigate = useNavigate();
  const [joinTeamId, setJoinTeamId] = useState<string | null>(null);
  let authState = useSelector((state: RootState) => state.authReducer);
  const teamState = useSelector((state: RootState) => state.teamStore);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!authState.userInfo) {
      navigate("/");
    } else {
      if (searchParams.get("join")) {
        setJoinTeamId(searchParams.get("join"));
        onOpen();
      }
      dispatch(getTeamThunk(authState.userInfo.uid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, navigate, dispatch]);

  return (
    <div>
      <section className="main-container w-screen h-screen overflow-hidden">
        <HacklabHeader basic={false} />
        {joinTeamId && (
          <JoinTeamModal
            isOpen={isOpen}
            onClose={onClose}
            teamId={joinTeamId!}
          />
        )}
        {teamState.status === AppState.loading ? (
          <div className="w-fit  pt-32 m-auto">
            <AppSpinner />
          </div>
        ) : (
          <section className="main-container flex h-full">
            <Sidebar
              title="Pages"
              width="300px"
              backgroundColor="white"
              className="overflow-y-auto"
              breakPoint="sm"
            >
              <TeamsSideBar />
            </Sidebar>

            <section className="inner-main-container overflow-y-auto w-full h-full md:h-[95%] pr-4 lg:pr-[10%]">
              {/* <div className="video-session-btn-container w-[100%] flex justify-end py-5">
                  <Button
                    variant={"outline"}
                    size={"lg"}
                    leftIcon={<CameraVideoFill />}
                  >
                    Video Session
                  </Button>
                </div> */}
              <div className="pl-4 md:pl-6 lg:pl-10 mt-4">
                {teamState.team == null ? <JoinTeam /> : <Outlet />}
              </div>
            </section>
          </section>
        )}
      </section>
    </div>
  );
}

export default TeamDashboard;
