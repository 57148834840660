import { Button } from "@chakra-ui/react";
import { HacklabHeader } from "@components/shared/Header";
import styles from "@styles/Dashboard.module.scss";
import { CodeSlash } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

function TopBanner(props: any) {
  return (
    <div className={styles.main + ""}>
      <div className="h-fit">
        <HacklabHeader />
      </div>
      <div className="flex flex-col justify-center gap-10 sm:gap-14 md:gap-[50px] ml-4 md:ml-[200px] ">
        <h2 className="text-left text-[50px] lg:text-[64px] text-[#D4E5FF] font-sans font-bold tracking-[0.005em]">
          Ecosystem Enablement <br />{" "}
          <span className="text-[#fff]">Zambia 2023</span>
        </h2>

        <div className="button-div flex flex-row items-center gap-4 md:gap-6 ">
          <Link to={"/dashboard"}>
            <Button
              size={"lg"}
              variant="solid"
              bgColor={"primary"}
              color={"white"}
              leftIcon={<CodeSlash />}
              className=" "
            >
              Compete
            </Button>
          </Link>

          <Link to={"/practice"}>
            <Button
              size={"lg"}
              color={"white"}
              variant={"outline"}
              className=""
            >
              Practice Now
            </Button>
          </Link>
        </div>
      </div>
      <div></div>
    </div>
  );
}

TopBanner.propTypes = {};

export default TopBanner;
