import { TeamMember } from "@/interfaces/timeline";
import { HacklabTeam } from "@/interfaces/user";
import { RootState, useAppDispatch } from "@/redux/app.store";
import {
  createTeamThunk,
  getTeamThunk,
  updateTeamThunk,
} from "@/redux/slices/team/getTeam";
import { INTERESTS } from "@/utils/data";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { MultiSelect } from "chakra-multiselect";
import { Check2 } from "react-bootstrap-icons";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

interface NewTeamModalProps {
  isOpen: boolean;
  onClose: any;
  team?: HacklabTeam | null;
}

function NewTeamModal({ isOpen, onClose, team }: NewTeamModalProps) {
  const toast = useToast();
  const state = useSelector((state: RootState) => state.authReducer);
  const dispatch = useAppDispatch();
  const editMode = team?.id != null;

  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: team?.name,
      alias: team?.alias,
      interests: team?.interests,
      description: team?.description,
    },
  });

  async function onSubmit($event: any) {
    try {
      let member: TeamMember = {
        image: state.userInfo?.photoURL ?? "",
        name: state.userInfo?.displayName ?? "",
        role: "",
        uid: state.userInfo?.uid ?? "",
      };
      if (editMode) {
        await dispatch(
          updateTeamThunk({
            ...$event,
            id: team.id,
          })
        ).unwrap();
        if (state.userInfo?.uid) dispatch(getTeamThunk(state.userInfo?.uid));
        toast({
          description: "Team updated successfully",
        });
        onClose();
      } else {
        await dispatch(
          createTeamThunk({
            ...$event,
            ownerId: state.userInfo?.uid ?? "",
            owner: member,
          })
        ).unwrap();
        if (state.userInfo?.uid) dispatch(getTeamThunk(state.userInfo?.uid));
        toast({
          description: "Team created successfully",
        });
        onClose();
      }
    } catch (error: any) {
      toast({
        description: error["message"],
      });
    }
  }

  return (
    <>
      <Modal
        size={"xl"}
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>
              {editMode ? "Edit your team" : "Create your team"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody className="space-y-4" pb={6}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState: { isTouched, invalid } }) => (
                  <FormControl isInvalid={isTouched && invalid}>
                    <FormLabel>Team Name</FormLabel>
                    <Input {...field} placeholder="Team name" />
                  </FormControl>
                )}
              />
              <Controller
                name="alias"
                control={control}
                render={({ field, fieldState: { isTouched, invalid } }) => (
                  <FormControl isInvalid={isTouched && invalid}>
                    <FormLabel>Team Alias</FormLabel>
                    <InputGroup>
                      <Input {...field} placeholder="Team Alias" />
                      <InputRightElement pointerEvents="none">
                        <Check2 />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                )}
              />

              <Controller
                name="interests"
                control={control}
                render={({ field, fieldState: { invalid } }) => (
                  <FormControl>
                    <MultiSelect
                      {...field}
                      options={INTERESTS.map((v) => ({
                        label: v.name,
                        value: v.name,
                      }))}
                      value={field.value}
                      label="Team interests"
                      create
                    />
                    {/* <Select multiple {...field} placeholder="Select option">
                      {INTERESTS.map((s) => (
                        <option key={s.name} value={s.name}>
                          {s.name}
                        </option>
                      ))}
                    </Select> */}
                  </FormControl>
                )}
              />

              <Controller
                name="description"
                control={control}
                render={({ field, fieldState: { isTouched, invalid } }) => (
                  <FormControl isInvalid={isTouched && invalid}>
                    <FormLabel>Team Dio</FormLabel>
                    <Textarea
                      {...field}
                      placeholder="Tell us something about the team"
                    />
                  </FormControl>
                )}
              />
            </ModalBody>

            <ModalFooter className="space-x-4">
              <Button onClick={onClose}>Cancel</Button>
              <Button
                disabled={isDirty && !isValid}
                type="submit"
                colorScheme="blue"
                mr={3}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

export default NewTeamModal;
