import { Avatar } from "@chakra-ui/react";

interface TeamScoresProps {
  username: string;
  email: string;
  score: string;
  image?: string;
}

export default function TeamScores(props: TeamScoresProps) {
  return (
    <div>
      <section className="entire-scores-wrapper w-fit p-4 flex flex-row items-center justify-between">
        <div className="profile-container flex flex-row items-center p-4">
          <Avatar name={props.username} src={props.image} />
          <div className="info ml-5">
            <h2 className="font-sans">{props.username}</h2>
            <h2 className="text-[#6B7280] font-sans">{props.email}</h2>
          </div>
          {/* <app-circular-status class="absolute left-[22%] top-[40%]"></app-circular-status> */}
        </div>

        <div className="hackathon-score-container bg-[#7357F614] rounded-[17px] p-6 ml-5">
          <h2 className="text-[20px] font-sans">Current Hackathon Scores</h2>
          <h2 className="text-[30px] text-[#5D5FEF] font-sans font-[900]">
            {props.score}
          </h2>
        </div>
      </section>
    </div>
  );
}
