"use client";

import EmptyDashboard from "@/components/shared/EmptyDashboard";
import { Project } from "@/interfaces/project";
import ProjectItem from "@components/shared/ProjectItem";
import SectionTitle from "@components/shared/SectionTitle";

function ProjectScore() {
  let projects: Project[] = [];

  return (
    <>
      <SectionTitle title="Scores for Projects" />

      <div className="space-y-3">
        {projects.length === 0 ? (
          <EmptyDashboard />
        ) : (
          projects.map((v) => <ProjectItem key={v.id} project={v} />)
        )}
      </div>
    </>
  );
}

export default ProjectScore;
