import { Avatar, Button, Flex, useDisclosure } from "@chakra-ui/react";

import { AppAuthState } from "@/interfaces/user";
import { RootState } from "@/redux/app.store";
import {
  ChevronDown,
  CodeSlash,
  HeartPulse,
  List,
} from "react-bootstrap-icons";
import { useProSidebar } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import ProfileModal from "../modals/ProfileModal";
import HacklabLogo from "./HacklabLogo";

function HacklabHeader(props: { basic?: boolean }) {
  const state = useSelector((state: RootState) => state.authReducer);
  let extra = props.basic
    ? "bg-gradient-to-b from-[#00000085] to-[#63687900] bg-opacity-[1%]"
    : "bg-[#0E1427]";

  return (
    <div>
      <div className="sm:hidden bg-[#0E1427]">
        <MobileHeader user={state} />
      </div>
      <div className="hidden sm:block">
        <DesktopHeader user={state} extra={extra} />
      </div>
    </div>
  );
}

function MobileHeader({ user }: { user: Partial<AppAuthState> }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { toggleSidebar } = useProSidebar();

  return (
    <>
      <ProfileModal isOpen={isOpen} onClose={onClose} />
      <Flex
        className="px-4 "
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <HacklabLogo />

        <Flex gap={"2"} justifyContent={"center"} alignItems={"center"}>
          {user.userInfo && (
            <Avatar
              onClick={onOpen}
              background={"whiteAlpha.200"}
              name={user.userInfo?.displayName ?? undefined}
              src={user.userInfo?.photoURL ?? undefined}
            />
          )}
          <Button onClick={() => toggleSidebar(true)} variant="link">
            <List color="white" size={30} />
          </Button>
        </Flex>
      </Flex>
    </>
  );
}

function DesktopHeader({
  extra,
  user,
}: {
  user: Partial<AppAuthState>;
  extra: string;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { pathname } = useLocation();
  const isAdminPath = matchPath("/dashboard/*", pathname);
  const navigate = useNavigate();

  return (
    <div
      className={
        "entire-nav-wrapper w-[100%] h-20 lg:h-24 flex justify-around items-center " +
        extra
      }
    >
      <ProfileModal isOpen={isOpen} onClose={onClose} />

      <HacklabLogo />

      <div className="inner-nav flex flex-row gap-4  items-center">
        {/* <Link to={"/practice"}>
          <MenuItem>
            <h2>Practice</h2>
          </MenuItem>
        </Link> */}

        <MenuItem to={"/compete"}>
          <CodeSlash />
          <h2>Compete</h2>
        </MenuItem>

        {/* <MenuItem to={"/opportunities"}>
          <h2>Opportunities</h2>
          <Badge borderRadius={"3xl"} />
        </MenuItem> */}

        {/* <MenuItem to={"/leader-board"}>
          <AwardFill />
          <h2>Leader Board</h2>
          <ChevronDown />
        </MenuItem> */}

        <MenuItem to={"/donate"} target="_blank">
          <HeartPulse />
          <h2>Donate</h2>
          <ChevronDown />
        </MenuItem>

        {user?.userInfo && (
          <>
            {/* <MenuItem>
              <Bell />
            </MenuItem> */}

            <div
              onClick={() => {
                if (isAdminPath) {
                  onOpen();
                } else {
                  navigate("/dashboard");
                }
              }}
              className="flex flex-row items-center bg-white bg-opacity-[10%] hover:bg-opacity-[30%] transition-all py-2 px-3 rounded-md min-w-max gap-4"
            >
              <h2 className="text-white">
                {user.userInfo.displayName?.split(" ")[0]}
              </h2>
              <Avatar
                size={"sm"}
                name={user.userInfo.displayName ?? ""}
                src={user.userInfo.photoURL ?? ""}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function MenuItem(props: {
  to: string;
  target?: string;
  className?: string;
  children: any;
}) {
  const { pathname } = useLocation();
  const isAdminPath = matchPath(props.to, pathname);
  return (
    <Link to={props.to} target={props.target}>
      <Button
        variant={"ghost"}
        color={"white"}
        isActive={isAdminPath !== null}
        className={
          "flex flex-row items-center gap-2 hover:text-dark transition-all " +
          props.className
        }
      >
        {props.children}
      </Button>
    </Link>
  );
}

export { DesktopHeader, HacklabHeader, MobileHeader };
