import { HackerAdminRole, HackerRole } from "@utils/constants"
import { User } from 'firebase/auth'
import { AppState } from "./base"
import { TeamMember } from "./timeline"
// 

export interface HacklabTeam {
    ownerId?: string,
    alias?: string,
    name?: string,
    id?: string,
    interests?: string[],
    description?: string,
    role?: HackerRole,
    members: TeamMember[],
    memberIds?: string[],
}

export interface Hacker {
    uid: string
    country: string,
    institution: string,
    bio: string,
    github: string,
    twitter: string,
    linkedin: string,
    website: string,
    stack: string[],
    languages: string[],
    codeName: string,
    displayName: string,
    phoneNumber: string,
    photoURL: string,
    interests: [],
    updatedOn?: any,
    createdOn?: any,

}

export interface AdminHacker {
    role?: HackerAdminRole
}


export interface AppAuthState { userInfo: User | null, state: AppState, error: string | null }

export enum AuthType { SignIn = 'SignIn', SignUp = 'SignUp' }
export enum AuthMethod { Email = 'Email', Google = 'Google', Github = 'Github', Listen = 'Listen' }