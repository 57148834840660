import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { AuthReducer } from './slices/auth'
import { TeamReducer } from './slices/team/getTeam'

export const appStore = configureStore({
  reducer: {
    authReducer: AuthReducer,
    teamStore: TeamReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({

      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof appStore.dispatch
export const useAppDispatch = () => useDispatch<typeof appStore.dispatch>()
