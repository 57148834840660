"use client";
import { Flex } from "@chakra-ui/react";
import AppSpinner from "./AppSpinner";

function Loading() {
  return (
    <Flex
      width={"full"}
      height={"full"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {/* <CircularProgress isIndeterminate color='primary' />
       */}
      <AppSpinner />
    </Flex>
  );
}

export default Loading;
