import { Hacker } from "@/interfaces/user";
import { RootState, useAppDispatch } from "@/redux/app.store";
import { signOutThunk } from "@/redux/slices/auth";
import { Firestore } from "@/services/firebase";
import aboutYou from "@assets/svgs/about_you.svg";
import {
  Avatar,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  useDisclosure,
} from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import {
  Briefcase,
  CodeSlash,
  Github,
  Globe2,
  Linkedin,
  Lock,
  PinMap,
  Robot,
  Twitter,
} from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFirestoreDocData } from "reactfire";
import AppSpinner from "../shared/AppSpinner";
import EditProfileModal from "./EditProfileModal";
export interface ProfileModalProps {
  isOpen: boolean;
  onClose: any;
}

function ProfileModal({ isOpen, onClose }: ProfileModalProps) {
  const editModal = useDisclosure();
  const authState = useSelector(
    (state: RootState) => state.authReducer
  ).userInfo;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data, status, error } = useFirestoreDocData(
    doc(Firestore, "users", authState?.uid ?? "random")
  );
  function isValidHttpUrl(string: string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }
  let userInfo: Hacker = data as any;

  async function signOut() {
    await dispatch(signOutThunk()).unwrap();
    navigate("/");
  }

  return (
    <>
      <EditProfileModal
        isOpen={editModal.isOpen}
        onClose={editModal.onClose}
        userInfo={userInfo as Hacker}
      />
      <Modal
        size={"2xl"}
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Hacker Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {status === "loading" ? (
              <AppSpinner />
            ) : userInfo?.updatedOn === undefined ? (
              <UserNotFound
                func={() => {
                  onClose();
                  return editModal.onOpen();
                }}
              />
            ) : (
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full ">
                <div className="px-6">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full px-4 flex justify-center">
                      <div className="relative">
                        <Avatar
                          size={"2xl"}
                          name={userInfo?.displayName}
                          src={userInfo.photoURL}
                        />
                      </div>
                    </div>
                    <div className="w-full px-4 text-center">
                      <div className="flex justify-center py-4 lg:pt-4 pt-8 space-x-4">
                        {isValidHttpUrl(userInfo.twitter) && (
                          <div className=" p-3 text-center">
                            <span className="text-2xl font-bold block uppercase tracking-wide text-blueGray-600">
                              <Link to={userInfo.twitter} target="_blank">
                                <Twitter />
                              </Link>
                            </span>
                          </div>
                        )}
                        {isValidHttpUrl(userInfo.linkedin) && (
                          <div className="p-3 text-center">
                            <span className="text-2xl font-bold block uppercase tracking-wide text-blueGray-600">
                              <Link to={userInfo.linkedin} target="_blank">
                                <Linkedin />
                              </Link>
                            </span>
                          </div>
                        )}
                        {isValidHttpUrl(userInfo.github) && (
                          <div className=" p-3 text-center">
                            <span className="text-2xl font-bold block uppercase tracking-wide text-blueGray-600">
                              <Link to={userInfo.github} target="_blank">
                                <Github />
                              </Link>
                            </span>
                          </div>
                        )}
                        {isValidHttpUrl(userInfo.website) && (
                          <div className=" p-3 text-center">
                            <span className="text-2xl font-bold block uppercase tracking-wide text-blueGray-600">
                              <Link to={userInfo.website} target="_blank">
                                <Globe2 />
                              </Link>
                            </span>
                          </div>
                        )}
                      </div>
                      {/* <div className="flex justify-center py-4 lg:pt-4 pt-8 space-x-4">
                        <div className=" p-3 text-center">
                          <span className="text-2xl font-bold block uppercase tracking-wide text-blueGray-600">
                            0
                          </span>
                          <span className="text-sm text-muted">Blogs</span>
                        </div>
                        <div className="p-3 text-center">
                          <span className="text-2xl font-bold block uppercase tracking-wide text-blueGray-600">
                            0
                          </span>
                          <span className="text-sm text-muted">Followers</span>
                        </div>
                        <div className=" p-3 text-center">
                          <span className="text-2xl font-bold block uppercase tracking-wide text-blueGray-600">
                            0
                          </span>
                          <span className="text-sm text-muted">Claps</span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="text-center mt-2">
                    <h3 className="text-xl font-semibold leading-normal text-blueGray-700 mb-2">
                      {userInfo?.displayName}
                    </h3>
                    <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase flex items-center justify-center gap-4">
                      <Lock />
                      <p>{userInfo.codeName}</p>
                    </div>

                    <div className="mb-2 text-blueGray-600 mt-6 flex items-center justify-center gap-4">
                      <PinMap />
                      <p>{userInfo?.country ?? "N/A"}</p>
                    </div>

                    <div className="mb-2 text-blueGray-600 flex items-center justify-center gap-4">
                      <Briefcase />
                      <p>{userInfo?.institution ?? "N/A"}</p>
                    </div>
                    <div className="mb-2 text-blueGray-600 flex items-center justify-center gap-4">
                      <CodeSlash />
                      <p className="space-x-2 ">
                        {[
                          ...(userInfo?.languages ?? []),
                          ...(userInfo?.stack ?? []),
                        ].map((v) => (
                          <Tag key={v} className="">
                            {v}
                          </Tag>
                        ))}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4 py-6 border-t border-blueGray-200 text-center">
                    <div className="flex flex-wrap justify-center">
                      <Robot />
                      <div className=" px-4">
                        <p>{userInfo?.bio}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </ModalBody>

          <ModalFooter className="space-x-4">
            <Button variant={"outline"} colorScheme="red" onClick={signOut}>
              Sign Out
            </Button>
            <Button variant={"ghost"} onClick={onClose}>
              Close
            </Button>
            {userInfo && (
              <Button
                onClick={() => {
                  onClose();
                  editModal.onOpen();
                }}
                variant="solid"
              >
                Update Info
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function UserNotFound({ func }: { func: Function }) {
  return (
    <div>
      <main className=" flex flex-col justify-center items-center py-8 space-y-3">
        <div>
          <img className="h-80" src={aboutYou} alt="" />
        </div>
        <p>Tell us more about your self</p>
        <Button onClick={() => func()}>Set up profile</Button>
      </main>
    </div>
  );
}
// function UserNotFound({ error }: any) {
//   return (
//     <div>
//       <main className="h-full w-full flex flex-col justify-center items-center py-8 ">
//         <h1 className="text-9xl font-extrabold text-[#1A2238] tracking-widest">
//           404
//         </h1>
//         <div className="bg-[#FF6A3D] px-2 text-sm rounded rotate-12 absolute">
//           Hacker's doesn't exist
//         </div>
//         <p>{error}</p>
//       </main>
//     </div>
//   );
// }

export default ProfileModal;
