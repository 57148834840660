import React from "react";
import { ChevronDown } from "react-bootstrap-icons";
import { MenuItem } from "react-pro-sidebar";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

interface SideBarItemProps {
  leading: React.ReactNode;
  title: string;
  href: string;
  params?: any;
  onClick: React.MouseEventHandler;
}
export default function SideBarItem(props: Partial<SideBarItemProps>) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isAdminPath = matchPath("/dashboard/" + props.href, pathname);
  return (
    <MenuItem
      icon={props.leading}
      onClick={() => {
        if (props.href) {
          navigate(props.href);
        }
        // props?.onClick();
      }}
      suffix={<ChevronDown />}
      active={isAdminPath !== null}
    >
      <h2 className="text-base font-medium text-dark2 m-0">{props.title}</h2>
    </MenuItem>
  );
}
