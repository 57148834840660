import { DONATE_PAGE } from "@/utils/constants";
import { useEffect } from "react";

function Donate() {
  useEffect(() => {
    window.open(DONATE_PAGE);
  }, []);

  return <div className="h-full w-full bg-primary"></div>;
}

export default Donate;
