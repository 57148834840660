import { HacklabMenu } from "@/interfaces/menu";
import { Badge } from "@chakra-ui/react";
import { AwardFill, ChevronDown, CodeSlash } from "react-bootstrap-icons";

export enum HackerRole {
  Admin = "admin",
  Analyst = "analyst",
  Frontend = "frontend",
  UI = "UI",
  Backend = "backend",
}

export enum HackerAdminRole {
  Admin = "admin",
  Supervisor = "supervisor",
  Mentor = "mentor",
  Judge = "judge",
}

export let hacklabMenus: HacklabMenu[] = [
  {
    title: "Practice",
    icon: "",
    path: "practice",
  },
  {
    title: "Compete",
    icon: <CodeSlash />,
    path: "compete",
  },
  {
    title: "Opportunities",
    icon: <Badge></Badge>,
    path: "opportunities",
  },
  {
    title: "Leaderboard",
    icon: <AwardFill />,
    path: "leaderboard",
    trail: <ChevronDown />,
  },
];

export let socialUrls = {
  facebook: "https://web.facebook.com/hacklabfdn/",
  twitter: "https://twitter.com/hacklabfdn",
  youtube: "https://www.youtube.com/channel/UCaI2NejPGrlR1lSGXmtqgDw",
  instagram: "https://www.instagram.com/hacklabfdn/",
  linkedIn: "https://www.linkedin.com/company/hacklabfdn/",
  githubUsername: "Hacklab-Foundation",
  video: "https://www.youtube.com/watch?v=DC4BLB2n9ss",
  githubProfile: "https://github.com/Hacklab-Foundation/",
  terms: "https://hacklabfoundation.org/terms",
  privacy: "https://hacklabfoundation.org/privacy",
  disclaimer: "https://hacklabfoundation.org/disclaimer",
};

export let DONATE_PAGE = "https://hacklabfoundation.org/donate";
export let TERM_PAGE = "https://hacklabfoundation.org/terms";
export let PRIVACY_PAGE = "https://hacklabfoundation.org/donate";
export let DISCLAIMER_PAGE = "https://hacklabfoundation.org/donate";
export let PARTNERS_PAGE = "https://hacklabfoundation.org/about";

export let agreement = (
  <>
    Your privacy is important to us. It is Brainstorming's policy to respect
    your privacy regarding any information we may collect from you across our
    website, and other sites we own and operate.
    <br />
    We only ask for personal information when we truly need it to provide a
    service to you. We collect it by fair and lawful means, with your knowledge
    and consent. We also let you know why we’re collecting it and how it will be
    used.
    <br />
    We only retain collected information for as long as necessary to provide you
    with your requested service. What data we store, we’ll protect within
    commercially acceptable means to prevent loss and theft, as well as
    unauthorized access, disclosure, copying, use or modification.
    <br />
    We don’t share any personally identifying information publicly or with
    third-parties, except when required to by law.
  </>
);

export const FormPatterns = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
};

export const AppConstants = {
  teamCol: "teams",
};
