

export const INTERESTS = [
    {
        "name": "Agile methods"
    },
    {
        "name": "Activism"
    },
    {
        "name": "Authenticity"
    },
    {
        "name": "GNI"
    },
    {
        "name": "Blockchain"
    },
    {
        "name": "Blogging"
    },
    {
        "name": "Coaching"
    },
    {
        "name": "Corporate Social Responsibility"
    },
    {
        "name": "Creative thinking"
    },
    {
        "name": "Democracy"
    },
    {
        "name": "Digital Media"
    },
    {
        "name": "Digital Marketing"
    },
    {
        "name": "Digitization"
    },
    {
        "name": "Diversity"
    },
    {
        "name": "Eco-Design"
    },
    {
        "name": "Emancipation"
    },
    {
        "name": "Energy efficiency"
    },
    {
        "name": "Engagement"
    },
    {
        "name": "Development"
    },
    {
        "name": "Nutrition"
    },
    {
        "name": "Europe"
    },
    {
        "name": "Fair Finance"
    },
    {
        "name": "Family"
    },
    {
        "name": "Finance"
    },
    {
        "name": "Refugee Solidarity"
    },
    {
        "name": "Freedom"
    },
    {
        "name": "Peace"
    },
    {
        "name": "Gastronomy"
    },
    {
        "name": "Money system"
    },
    {
        "name": "Community"
    },
    {
        "name": "Genetic engineering"
    },
    {
        "name": "Justice"
    },
    {
        "name": "Social commitment"
    },
    {
        "name": "Graphic Design"
    },
    {
        "name": "Basic Income"
    },
    {
        "name": "Green Living"
    },
    {
        "name": "Impact"
    },
    {
        "name": "Improvisation"
    },
    {
        "name": "Integration"
    },
    {
        "name": "Intercultural communication"
    },
    {
        "name": "Journalism"
    },
    {
        "name": "Youth"
    },
    {
        "name": "Children"
    },
    {
        "name": "Climate protection"
    },
    {
        "name": "Cooking"
    },
    {
        "name": "Cooperation"
    },
    {
        "name": "Creativity"
    },
    {
        "name": "Food"
    },
    {
        "name": "Lifestyle"
    },
    {
        "name": "Love"
    },
    {
        "name": "Local food"
    },
    {
        "name": "Marketing"
    },
    {
        "name": "Music"
    },
    {
        "name": "Muslim Community"
    },
    {
        "name": "Online Marketing"
    },
    {
        "name": "Organizational Development"
    },
    {
        "name": "Politics"
    },
    {
        "name": "Project Management"
    },
    {
        "name": "Facilitation"
    },
    {
        "name": "Regional"
    },
    {
        "name": "Regional Agriculture"
    },
    {
        "name": "Regional Development"
    },
    {
        "name": "Self-determination"
    },
    {
        "name": "Self-reflection"
    },
    {
        "name": "Social Media"
    },
    {
        "name": "Social Media Marketing"
    },
    {
        "name": "Software"
    },
    {
        "name": "Social Innovations"
    },
    {
        "name": "Social Innovations in the Global South"
    },
    {
        "name": "Social value added"
    },
    {
        "name": "Social Theater"
    },
    {
        "name": "Sufficiency"
    },
    {
        "name": "Unconscious bias"
    },
    {
        "name": "Event Management"
    },
    {
        "name": "Connectedness promotion"
    },
    {
        "name": "Association Foundation"
    },
    {
        "name": "Virtual Teams"
    },
    {
        "name": "World Food"
    },
    {
        "name": "World Peace"
    },
    {
        "name": "Economy of the Future"
    },
    {
        "name": "Zeitgeist"
    },
    {
        "name": "Sustainable Society"
    },
    {
        "name": "Change management"
    }
]


export const DEVELOPMENT = {
    programming_languages: [
        "Python",
        "Java",
        "JavaScript",
        "C#",
        "C++",
        "Ruby",
        "Swift",
        "Kotlin",
        "Go",
        "Rust",
        "PHP",
        "TypeScript",
        "Dart",
        "R",
        "MATLAB",
        "Perl",
        "Lua",
        "Scala",
        "Groovy",
        "Haskell"
    ].sort(),
    frameworks: [
        "React.js",
        "Angular",
        "Vue.js",
        "Django",
        "Ruby on Rails",
        "Express.js",
        "Flask",
        "React Native",
        "Flutter",
        "SwiftUI",
        "Android Studio",
        "Xamarin",
        "Node.js",
        "Spring Boot",
        "Ruby on Rails",
        "Flask",
        "Unity",
        "Unreal Engine",
        "TensorFlow",
        "PyTorch",
        "Scikit-learn",
        "Keras",
        "Pandas",
        "RStudio",
        "Electron",
        "PyQt",
        "wxWidgets",
        "Kubernetes",
        "Docker",
        "Ansible",
        "Terraform",
        "Arduino",
        "Raspberry Pi",
        "Particle"
    ].sort(),
    stacks: [
        "Web Development",
        "Mobile App Development",
        "Backend Development",
        "Game Development",
        "Data Science Machine Learning",
        "Desktop Application Development",
        "Devops Cloud",
        "Iot",
    ].sort()
}


export const COUNTRIES = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia",
    "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin",
    "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi",
    "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia",
    "Comoros", "Congo (Brazzaville)", "Congo (Kinshasa)", "Costa Rica", "Cote d'Ivoire", "Croatia", "Cuba", "Cyprus",
    "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador",
    "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia",
    "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti",
    "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica",
    "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan",
    "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar",
    "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia",
    "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal",
    "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan",
    "Palau", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
    "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa",
    "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore",
    "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan",
    "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo",
    "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates",
    "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen",
    "Zambia", "Zimbabwe"
]

