import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "./App.css";
import AuthLayout from "./app/auth/layout";
import LoginPage from "./app/auth/login/page";
import SignUpPage from "./app/auth/signup/page";
import TeamDashboard from "./app/dashboard/[teamId]/DashboardHome";
import Achievements from "./app/dashboard/[teamId]/achievements/page";
import Projects from "./app/dashboard/[teamId]/projects/page";
import ProjectScore from "./app/dashboard/[teamId]/scores/page";
import TeamSession from "./app/dashboard/[teamId]/sessions/page";
import TeamSubmission from "./app/dashboard/[teamId]/submissions/page";
import DashboardIndex from "./app/dashboard/compete";
import Donate from "./app/donate/donate";
import Home from "./app/page";
import Practice from "./app/practice/page";
import { ProtectedRoute } from "./components/auth/Protected";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "auth",
    element: <AuthLayout />,

    children: [
      {
        path: "",
        element: <Navigate to="login" replace />,
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "signup",
        element: <SignUpPage />,
      },
    ],
  },
  {
    path: "compete",
    element: <DashboardIndex />,
  },
  {
    path: "dashboard",
    element: (
      <ProtectedRoute>
        <TeamDashboard />
      </ProtectedRoute>
    ),

    children: [
      {
        path: "",
        element: <Navigate to="projects" replace={true} />,
      },
      {
        path: "achievements",
        element: <Achievements />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "scores",
        element: <ProjectScore />,
      },
      {
        path: "submissions",
        element: <TeamSubmission />,
      },
      {
        path: "sessions",
        element: <TeamSession />,
      },
    ],
  },

  {
    path: "donate",
    element: <Donate />,
  },
  // {
  //   path: "leader-board",
  //   element: <LeaderBoard />,
  // },
  // {
  //   path: "opportunities",
  //   element: <Opportunities />,
  // },
  {
    path: "practice",
    element: <Practice />,
  },
]);

function App() {
  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(
  //     appAuthThunk({
  //       method: AuthMethod.Listen,
  //       payload: null,
  //     })
  //   );
  // }, [dispatch]);

  return <RouterProvider router={router} />;
}

export default App;
