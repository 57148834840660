import React from "react";
import PropTypes from "prop-types";
import { ButtonProps } from "@chakra-ui/react";

function HackButton(props: ButtonProps) {
  const buttonType =
    "text-[#fff] flex gap-2 items-center rounded-[6px] mr-[20px] justify-between bg-[#5D5FEF] p-[15px]   h-[50px]";

  return (
    <button className={buttonType}>
      <img src="/assets/svgs/code.svg" alt="" />
      Compete
    </button>
  );
}

HackButton.propTypes = {};

export default HackButton;
