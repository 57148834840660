import { Clock, ClockFill } from "react-bootstrap-icons";

function Timeline(props: any) {
  return (
    <section className="entire-timeline-wrapper font-semibold py-[25px] md:py-[50px] gap-6 md:gap-16 flex flex-col  md:flex-row items-center justify-center">
      <div className="start-time-div ">
        <div className="start-upper text-2xl md:text-[26px] flex flex-row items-center gap-4">
          <ClockFill />
          <h2 className="text-[26px] ">Starts On</h2>
        </div>

        <div className="start-lower">
          <h1 className="text-3xl md:text-[40px] ">
            Jan 16, 2023 | 09:30 AM ZT
          </h1>
        </div>
      </div>

      <div className="end-time-div">
        <div className="text-2xl md:text-[26px] end-upper flex flex-row items-center gap-4">
          <Clock className=" " />
          <h2 className="">Ends On</h2>
        </div>

        <div className="end-lower ">
          <h1 className="text-3xl md:text-[40px] ">
            Jan 21, 2023 | 09:30 PM ZT
          </h1>
        </div>
      </div>
    </section>
  );
}

Timeline.propTypes = {};

export default Timeline;
