import { Firestore } from "@/services/firebase";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { arrayRemove, arrayUnion, collection, doc, getCountFromServer, query, updateDoc, where } from "firebase/firestore";
import { getTeamThunk } from "./getTeam";

export interface JoinTeam {
    uid: string,
    name: string,
    role: string,
    teamId: string,
    image: string
}

export const joinTeamThunk = createAsyncThunk('team/join', async (payload: JoinTeam, { dispatch }) => {
    let path = doc(Firestore, 'teams', payload.teamId);
    let q = query(collection(Firestore, 'teams'), where('memberIds', 'array-contains', payload.uid))
    let count = await getCountFromServer(q);

    if (count.data().count > 0) {
        throw Error('Please you can\'t join multiple teams')
    }
    try {
        await updateDoc(path, {
            memberIds: arrayUnion(payload.uid),
            members: arrayUnion({
                image: payload.image,
                uid: payload.uid,
                name: payload.name,
                role: payload.role,
                joinOn: new Date()
            },)
        })
        dispatch(getTeamThunk(payload.uid))
    } catch (error) {
        throw Error('Failed to join team')
    }
})


export const removeTeamMemberThunk = createAsyncThunk('team/join', async (payload: { uid: string, teamId: string }, { dispatch }) => {
    let path = doc(Firestore, 'teams', payload.teamId);
    await updateDoc(path, {
        memberIds: arrayRemove(payload.uid),
        members: arrayRemove({
            uid: payload.uid
        },)
    })
    dispatch(getTeamThunk(payload.uid))
})