import { HacklabTeam } from "@/interfaces/user";
import { RootState, useAppDispatch } from "@/redux/app.store";
import { getTeamThunk } from "@/redux/slices/team/getTeam";
import { JoinTeam, joinTeamThunk } from "@/redux/slices/team/manageTeam";
import { Firestore } from "@/services/firebase";
import {
  Avatar,
  Button,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Search } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import AppSpinner from "../shared/AppSpinner";

export interface JoinTeamModalProps {
  isOpen: boolean;
  onClose: any;
  teamId?: string;
}

function JoinTeamModal({ isOpen, onClose, teamId }: JoinTeamModalProps) {
  const toast = useToast();
  const [id, setId] = useState<string | null>(teamId ?? null);
  const [value, setValue] = useState<string>(teamId ?? "");
  const [team, setTeam] = useState<HacklabTeam | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const state = useSelector((state: RootState) => state.authReducer).userInfo;
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit,
  } = useForm();

  async function onSubmit() {
    if (!id) {
      toast({
        description: "Failed to join team; Please try again",
      });
      return;
    }
    try {
      let member: JoinTeam = {
        image: state!.photoURL ?? "",
        name: state!.displayName!,
        uid: state!.uid!,
        role: "",
        teamId: id!,
      };
      setLoading(true);
      // console.log(member);
      await dispatch(joinTeamThunk(member)).unwrap();

      await dispatch(getTeamThunk(state?.uid!)).unwrap();
      onClose();
      toast({
        description: "Team created successfully",
      });
    } catch (error: any) {
      toast({
        description:
          error["message"] ?? "Failed to join team, Please try again",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  async function getTeamById() {
    try {
      setLoading(true);
      let path = doc(Firestore, "teams/" + id);
      let _teamDoc = await getDoc(path);

      if (_teamDoc.exists()) {
        setTeam(_teamDoc.data() as HacklabTeam);
      } else {
        setTeam(null);
        toast({
          description: "Looks like team does not exist",
          status: "error",
        });
      }
    } catch (error) {
      toast({
        description: "Looks like something bad happened",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      getTeamById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Modal
        size={"xl"}
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent>
          {loading ? (
            <ModalBody>
              <div className="w-full py-11">
                <AppSpinner />
              </div>
            </ModalBody>
          ) : (
            <>
              <ModalHeader>Join {team?.name}</ModalHeader>
              <ModalCloseButton />
              <ModalBody className="space-y-4" pb={6}>
                <InputGroup>
                  <Input
                    value={value}
                    onChange={(v) => setValue(v.target.value)}
                    type="number"
                    placeholder="Enter team id"
                  />
                  <InputRightAddon
                    className="cursor-pointer"
                    onClick={() => {
                      setId(value);
                    }}
                    children={<Search />}
                  />
                </InputGroup>

                {team?.members && (
                  <Stack>
                    <p>Team Members</p>
                    {team?.members.map((v) => (
                      <div className="flex py-2 border-b border-b-gray-100 items-center gap-4">
                        <Avatar size={"md"} name={v.name} src={v.image} />
                        <h2>{v.name}</h2>
                      </div>
                    ))}
                  </Stack>
                )}
              </ModalBody>
            </>
          )}

          <ModalFooter className="space-x-4">
            <Button onClick={onClose}>Cancel</Button>
            <Button
              disabled={isDirty && !isValid}
              onClick={onSubmit}
              isLoading={loading}
              colorScheme="blue"
              mr={3}
            >
              Join
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default JoinTeamModal;
