import { Timestamp } from "firebase/firestore";


export interface BaseItem {
    createdAt: Timestamp,
    updatedAt: Timestamp,
    by: string,

}


export enum AppState { idle = 'idle', loading = 'loading', success = 'success', error = 'error' }