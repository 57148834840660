import image from "@assets/gifs/empty-page.gif";

interface EmptyDashboardProps {
  image: any;
}

export default function EmptyDashboard(props: Partial<EmptyDashboardProps>) {
  return (
    <>
      <div className="main-container flex justify-center items-center">
        <img
          className="max-w-[400px]  object-cover"
          src={props.image ?? image}
          alt="no projects"
        />
      </div>
      <p className="text-center text-lg font-semibold"> Noting Found </p>
    </>
  );
}
