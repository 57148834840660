import { socialUrls } from "@utils/constants";
import { Facebook, Instagram, Twitter, Youtube } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

function FooterRight(props: any) {
  return (
    <section className="entire-footer-right">
      <div className="upper">
        <h2 className="font-sans">Follow our socials</h2>
      </div>

      <div className="logos flex flex-row justify-evenly mt-5 items-center text-4xl text-primary">
        <Link target="_blank" to={socialUrls.facebook} rel="noreferrer">
          <Facebook />
        </Link>
        <Link target="_blank" to={socialUrls.twitter} rel="noreferrer">
          <Twitter />
        </Link>
        <Link target="_blank" to={socialUrls.youtube} rel="noreferrer">
          <Youtube />
        </Link>
        <Link target="_blank" to={socialUrls.instagram} rel="noreferrer">
          <Instagram />
        </Link>
      </div>

      <div className="lower flex flex-row mt-10 justify-between">
        <Link to={socialUrls.terms} target="_blank" className="font-sans">
          Terms and Conditions
        </Link>
        <Link to={socialUrls.privacy} target="_blank" className="font-sans">
          Privacy
        </Link>
        <Link to={socialUrls.disclaimer} target="_blank" className="font-sans">
          Disclaimer
        </Link>
      </div>
    </section>
  );
}

export default FooterRight;
