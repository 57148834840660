"use client";

import { RootState } from "@/redux/app.store";
import SectionTitle from "@components/shared/SectionTitle";
import TeamScores from "@components/shared/TeamScores";
import { useSelector } from "react-redux";

function Achievements() {
  let authState = useSelector((state: RootState) => state.authReducer).userInfo;

  return (
    <>
      <SectionTitle title="Achievements" />
      <div>
        <TeamScores
          username={authState?.displayName ?? ""}
          email={authState?.email ?? ""}
          score="0/0"
          image={authState?.photoURL ?? ""}
        />
      </div>
      <div className="awards-div mt-6">
        <h2 className="font-sans text-[20px] font-[600]">Awards</h2>
        <div>
          <h4 className="font-sans">Podium</h4>
          <div className="flex space-x-4 mt-4 overflow-x-auto">
            {/* <HacklabAward position="winner" prizeMoney="45000" year="2019" />
            <HacklabAward position="winner" prizeMoney="45000" year="2019" />
            <HacklabAward position="winner" prizeMoney="45000" year="2019" />
            <HacklabAward position="winner" prizeMoney="45000" year="2019" /> */}
          </div>
        </div>
        <div className="mt-4 md:mt-6">
          <h4 className="font-sans">Certificate</h4>
          <div className="flex space-x-4 mt-4 overflow-x-auto">
            {/* <HacklabCertificate />
            <HacklabCertificate />
            <HacklabCertificate />
            <HacklabCertificate /> */}
          </div>
        </div>
      </div>
      <div className="flex flex-row"></div>
    </>
  );
}

export default Achievements;
