export default function Rules() {
  let rules = [
    `This is an individual participation Hackathon ('Contest'); you
            must work on your hacks ('Solutions') and submissions individually.`,
    `   TThe contest will start at Sept 14, 2022 12:00 AM EST and end at
            Dec 31, 2022 11:59 PM EST; you may work on your solutions during the
            allotted time only.`,
    `This is an online Contest - you can participate from anywhere.`,
    `   Anyone eligible to compete in the contest is welcomed and
            encouraged to participate. However, you can only be an IBM zStudent
            contest winner two times total, consecutive or not.`,
    `  Two themes are defined for the Contest; you must submit a project
            that is in line with the theme(s).`,
    ` You will be required to create a profile on the IBM Z Xplore
            learning platform - this will provide you with appropriate access to
            an IBM zSystems, through a zSystem login profile. The contest Drops
            will be provided on the IBM Z Xplore learning platform.`,
    ` Drops will be provided on the IBM Z Xplore learning platform.
            During the contest, a series of code “Drops” will be released
            several days apart - each Drop is a technical challenge to explore
            test datasets, programmatically manipulate that data into a required
            output dataset, and perform a validation check for content and
            format. The complete solution will be an automatically executable
            sequence of scripts that can produce correct and complete output
            from a new set of compatible contest datasets.`,
    ` Once the contest starts, you will have the opportunity to submit
            your solutions for each Drop, for validation against the test
            datasets, and testing for correct and compliant output. You may run
            the validation check multiple times until you are satisfied that you
            have a working solution.`,
    ` Your final submission must be an executable script capable of
            being initiated, using your zSystems login profile rights, on an
            automatically scheduled basis. Your final submission must be “ready
            to run” within the zSystems platform - there must be no dependencies
            on local workstation tools or code.`,
    ` You are expected to come up with new and innovative ideas, any
            idea that has been copied from somewhere will be disqualified.`,
    ` Your solution must be developed entirely during the Contest
            duration. Solutions are possible using only utilities, programming
            languages and services existing on the zSystems platform (zOS and
            Unix System Services). You may use any pre-installed open source
            libraries, and other freely available systems/services accessible
            via the internet.`,
    ` The intellectual property of your code belongs only to you.`,
    ` By participating in the contest, you agree to the terms and
            conditions of Hacklab Foundation.`,
  ];
  return (
    <div>
      <section className="rules-wrapper">
        <ul className="list space-y-4 list-decimal">
          {rules.map((item, index) => {
            return <li className="text-[#6B7280] font-sans text-lg">{item}</li>;
          })}
        </ul>
      </section>
    </div>
  );
}
