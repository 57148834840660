
class Utils {
    static toDouble(n: number | string) {
        n = String(n)
        if (n.length == 1)
            n = '0' + n
        return n
    }


}



export { Utils }