import HackButton from "@components/shared/Button";
// bg - [url("/assets/images/hacker.png")];
function FooterMiddle() {
  return (
    <section className="entire-footer-wrapper w-[100%] h-[250px]  bg-center bg-no-repeat bg-cover m-0 p-[-40px]">
      <div className="inner-background w-[100%] h-[250px] bg-[#0e0e31b4] flex flex-row justify-center items-center">
        <HackButton />

        <button className="text-[#fff] flex items-center justify-between bg-transparent border-solid border-white border-2 rounded-[6px] p-[15px] w-[135px] h-[50px]">
          Practice Now
        </button>
      </div>
    </section>
  );
}

export default FooterMiddle;
