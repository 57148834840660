

export class AppStorage {
    static user = 'APP_USER';
    static getData<T>(key: string): T | null {
        try {
            let res = localStorage.getItem(key);

            if (res) {
                return JSON.parse(res)
            }
            return null;
        } catch (error: any) {
            // console.log(error)
            return error.toString()
        }
    }


    static setData(key: string, data: any) {
        if (data) {
            localStorage.setItem(key, JSON.stringify(data));
        }
    }

    static delete(key: string) {
        return localStorage.removeItem(key);
    }
}