import { getFirestore } from 'firebase/firestore';
import firebaseApp from './config';
import { getDownloadURL, getStorage, ref, uploadBytes, } from 'firebase/storage';


export const Firestore = getFirestore(firebaseApp);
export const FirebaseStorage = getStorage(firebaseApp);
// connectFirestoreEmulator(Firestore, 'http://127.0.0.1', 8080);



export async function uploadFile(file: File, uid: string) {
    try {
        const path = ref(FirebaseStorage, `projects/${uid}`);

        await uploadBytes(path, file);
        return await getDownloadURL(path);
    } catch (error) {
        throw Error('Failed to upload files')
    }
}

export async function uploadFiles(file: FileList, uid: string) {
    let urls = [];

    for (let index = 0; index < file.length; index++) {
        const element = file[index];
        let item = await uploadFile(element, uid)
        urls.push(item);

    }
    return urls;
}

