import { CameraVideo, Github } from "react-bootstrap-icons";
import { MenuItem } from "react-pro-sidebar";

export default function ConnectedApps() {
  return (
    <div>
      <section className="connected-apps-wrapper text-sm">
        <MenuItem disabled>
          <span className="text-muted">Connected Apps</span>
        </MenuItem>
        {/* 
        <MenuItem icon={<Kanban />}>
          <h2>Kanban</h2>
        </MenuItem>

        <MenuItem icon={<Calendar />}>
          <h2>Calendar</h2>
        </MenuItem> */}

        <MenuItem icon={<Github />}>
          <h2>Github</h2>
        </MenuItem>
        {/* 
        <MenuItem icon={<Google />}>
          <h2>Whiteboard</h2>
        </MenuItem> */}

        <MenuItem icon={<CameraVideo />}>
          <h2>Google Meet</h2>
        </MenuItem>

        {/* <MenuItem icon={<Folder2Open />}>
          <h2>File Manager</h2>
        </MenuItem> */}
      </section>
    </div>
  );
}

function SingleApp(props: { children: React.ReactNode; className?: string }) {
  return (
    <MenuItem>
      <div
        className={
          "nav flex flex-row items-center pb-3 space-x-4 text-[18px] text-dark " +
          props.className
        }
      >
        {props.children}
      </div>
    </MenuItem>
  );
}
