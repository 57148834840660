"use client";

import { RootState } from "@/redux/app.store";
import Login from "@assets/login.jpg";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

const AuthLayout = ({ children }: any) => {
  const selector = useSelector((state: RootState) => state.authReducer);
  const navigate = useNavigate();

  useEffect(() => {
    if (selector.userInfo) {
      navigate("/dashboard");
    }
  }, [selector, navigate]);

  return (
    <div className="h-screen w-screen grid grid-cols-12 ">
      <div className="hidden md:block h-full overflow-hidden  col-span-6 ">
        <img
          src={Login}
          className="w-full h-full bg-center object-cover"
          alt="hacker"
        />
      </div>
      <div className="col-span-12 md:col-span-6 h-full   flex justify-center items-center  ">
        <div className="w-full lg:w-[500px] md:mx-auto px-5  md:py-8 space-y-4 md:space-y-10 ">
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
