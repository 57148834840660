"use client";

import { AppState } from "@/interfaces/base";
import { AuthMethod, AuthType, Hacker } from "@/interfaces/user";
import { RootState, useAppDispatch } from "@/redux/app.store";
import { appAuthThunk } from "@/redux/slices/auth";
import { FormPatterns, agreement } from "@/utils/constants";
import Logo from "@assets/core/logo_black.png";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AuthWith from "@components/auth/AuthWith";
import { useState } from "react";
import { CheckCircle } from "react-bootstrap-icons";
import { Control, Controller, FieldValues, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const SignUpPage = () => {
  const { state, userInfo } = useSelector(
    (state: RootState) => state.authReducer
  );
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [userData, setUserData] = useState<Partial<Hacker>>({});
  let { isOpen, onOpen, onClose } = useDisclosure();

  const [stage, setStage] = useState<1 | 2>(1);

  const { handleSubmit, formState, control, setError } = useForm({
    defaultValues: userData,
  });

  let onsubmit = (data: any) => {
    if (stage === 1) {
      // console.log(userData);
      /// check if user name and email is valid
      if (FormPatterns.email.test(data.email) && data.username.trim() !== "") {
        setUserData(data);
        setStage(2);
      }
    } else if (stage === 2) {
      if (data.password === data.confirm_password) {
        setUserData({ ...data });
        onOpen();
      } else {
        /// mark password as incorrect
      }
    }
  };

  let signUp = async () => {
    const res = await dispatch(
      appAuthThunk({
        type: AuthType.SignUp,
        method: AuthMethod.Email,
        payload: userData,
      })
    );

    if (appAuthThunk.fulfilled.match(res)) {
      toast({
        description: "success",
        status: "success",
      });
    } else {
      toast({
        description: res.error.message,
      });
    }
  };

  let previous = () => {
    setStage(1);
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex gap-3 items-center ">
          <img src={Logo} className=" w-[35px] md:w-[50px]" alt="logo" />
          <h4 className=" text-2xl md:text-3xl font-bold">
            Ready to hack <span className="p-color">Your</span>Way!!
          </h4>
        </div>
        <div>
          <p className="text-lg">
            {stage === 1
              ? "Create your hacklab profile"
              : "Secure your account"}
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onsubmit)}>
        {/* <UserName control={control} next={forward} previous={previous} /> */}
        {stage === 1 ? (
          <UserName
            loading={state === AppState.loading}
            control={control}
            previous={previous}
          />
        ) : (
          <Security
            loading={state === AppState.loading}
            control={control}
            previous={previous}
          />
        )}
      </form>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={true}
        size={"xl"}
        isCentered
      >
        <ModalOverlay
          bgGradient={
            "linear-gradient(180deg, #262626 -67.65%, rgba(47, 15, 63, 0.82) 100%);transform: rotate(-180deg);"
          }
        />
        <ModalContent>
          <ModalHeader>
            <div className="flex gap-4 items-center">
              <img src={Logo} alt="logo" className="w-[40px]" />
              <p className="text-2xl">Terms and Conditions</p>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{agreement}</ModalBody>

          <ModalFooter className="flex justify-center">
            <div className="w-full flex justify-center mb-4">
              <Button
                onClick={() => {
                  onClose();
                  signUp();
                }}
                size={"lg"}
                leftIcon={<CheckCircle />}
              >
                Accept terms and conditions
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

function UserName(props: {
  control: Control<FieldValues>;
  previous: Function;
  loading: boolean;
}) {
  return (
    <>
      <AuthWith type={AuthType.SignUp} />

      <div className="my-5 ">
        <p className="text-muted">Or Create New Profile</p>
      </div>

      <div className="space-y-6 w-full lg:w-[80%]">
        <Controller
          name="username"
          control={props.control}
          render={({ field }) => (
            <FormControl>
              <FormLabel fontSize={"sm"}>Full Name</FormLabel>
              <Input
                size={"lg"}
                {...field}
                type="text"
                placeholder="Full Name"
              />
              {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
            </FormControl>
          )}
        />

        <Controller
          name="email"
          control={props.control}
          render={({ field }) => (
            <FormControl>
              <FormLabel fontSize={"sm"}>Email address</FormLabel>
              <Input
                size={"lg"}
                {...field}
                type="email"
                placeholder="Example@anything.com"
              />
              {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
            </FormControl>
          )}
        />

        <Button
          disabled={props.loading}
          bgColor={"primary"}
          textColor="white"
          type="submit"
          size={"lg"}
        >
          Sign up with mail
        </Button>
      </div>
      <div className="mt-4">
        <p className="text-lg">
          Already have an account?{" "}
          <Link href="/auth/login">
            <span className="p-color underline ml-1">sign in</span>
          </Link>
        </p>
      </div>
    </>
  );
}

function Security(props: {
  control: Control<FieldValues>;
  previous: Function;
  loading: boolean;
}) {
  return (
    <>
      <div className="space-y-6 w-full lg:w-[80%]">
        <Controller
          name="password"
          control={props.control}
          render={({ field }) => (
            <FormControl>
              <FormLabel size={"sm"}>password</FormLabel>
              <Input
                {...field}
                size={"lg"}
                type="password"
                placeholder="Password"
              />
              {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
            </FormControl>
          )}
        />

        <Controller
          name="confirm_password"
          control={props.control}
          render={({ field }) => (
            <FormControl>
              <FormLabel size={"sm"}>Confirm Password</FormLabel>
              <Input
                size={"lg"}
                {...field}
                type="password"
                placeholder="Confirm password"
              />
              {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
            </FormControl>
          )}
        />

        <div className="flex gap-4 pt-2">
          <Button
            variant={"outline"}
            onClick={() => {
              props.previous();
            }}
            size={"lg"}
          >
            Back
          </Button>
          <Button disabled={props.loading} type="submit" size={"lg"}>
            Sign Up
          </Button>
        </div>
      </div>
    </>
  );
}

export default SignUpPage;
