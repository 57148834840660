import joinTeam from "@assets/svgs/join-team.svg";
import TeamSettings from "../dashboard/TeamSettings";
function JoinTeam() {
  return (
    <div>
      <img src={joinTeam} className="max-w-[600px] mx-auto" alt="join team" />
      <TeamSettings showTitle={false} />
    </div>
  );
}

export default JoinTeam;
