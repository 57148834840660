"use client";

import { AuthMethod, AuthType } from "@/interfaces/user";
import { useAppDispatch } from "@/redux/app.store";
import { appAuthThunk } from "@/redux/slices/auth";
import { FormPatterns } from "@/utils/constants";
import Logo from "@assets/core/logo_black.png";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  useToast,
} from "@chakra-ui/react";
import AuthWith from "@components/auth/AuthWith";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm();

  async function onsubmit($event: any) {
    // console.log($event);

    let x = await dispatch(
      appAuthThunk({
        type: AuthType.SignIn,
        method: AuthMethod.Email,
        payload: $event,
      })
    );
    // console.log(x);
    if (appAuthThunk.fulfilled.match(x)) {
      toast({
        description: "Welcome back",
        status: "success",
      });

      navigate("/dashboard", {
        replace: true,
      });
    } else {
      toast({
        description: x.error.message,
        status: "error",
      });
    }
  }

  return (
    <div className="space-y-4 md:space-y-10 ">
      <div className="space-y-5">
        <div className="flex gap-3 items-center ">
          <img src={Logo} className=" w-[35px] md:w-[50px]" alt="logo" />
          <h4 className=" text-2xl md:text-3xl font-bold">
            Ready to hack <span className="p-color">Your</span>Way!!
          </h4>
        </div>
        <div>
          <p className="text-lg">sign in to your hacklab profile</p>
        </div>
      </div>
      <AuthWith type={AuthType.SignIn} title="Login with" />

      <form onSubmit={handleSubmit(onsubmit)}>
        <div className="space-y-6 w-full lg:w-[80%]">
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: FormPatterns.email,
                message: "Invalid email address",
              },
            }}
            render={({ field }) => (
              <FormControl {...field}>
                <FormLabel fontSize={"sm"}>Email Address</FormLabel>
                <Input size={"lg"} type="email" placeholder="Email Address" />
                {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
              </FormControl>
            )}
          />

          <Controller
            name="password"
            rules={{
              required: "Password is required",
              minLength: {
                message: "Password too short",
                value: 8,
              },
            }}
            control={control}
            render={({ field }) => (
              <FormControl {...field}>
                <FormLabel fontSize={"sm"}>Password</FormLabel>
                <Input size={"lg"} type="password" placeholder="Password" />
                {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
              </FormControl>
            )}
          />

          <Button
            type="submit"
            disabled={!isValid}
            variant={"solid"}
            bgColor={"primary"}
            color={"white"}
            size={"lg"}
          >
            Sign In
          </Button>
        </div>
      </form>
      <div>
        <p className="text-lg">
          {"Don't have an account?"}
          <Link href={"/auth/signup"}>
            <span className="p-color underline">sign up</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
