"use client"

import { HacklabHeader } from '@/components/shared/Header'
import React from 'react'

function Practice() {
    return (
        <div>
            <HacklabHeader></HacklabHeader>

            <div>
                Coming soon;
            </div>
        </div>
    )
}

export default Practice