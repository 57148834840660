"use client";

import EmptyDashboard from "@/components/shared/EmptyDashboard";
import { Avatar, Badge, Box, Button, Flex } from "@chakra-ui/react";
import SectionTitle from "@components/shared/SectionTitle";
import { ReactNode } from "react";
import {
  ChevronRight,
  CircleFill,
  FilePdfFill,
  People,
} from "react-bootstrap-icons";

function TeamSubmission() {
  return (
    <SectionTitle title="Team Submission">
      <div className="flex justify-end">
        <Button variant={"solid"} paddingY={"6"} leftIcon={<People />}>
          Make a Submission
        </Button>
      </div>
      <div className="flex gap-4 lg:gap-10">
        <EmptyDashboard />
        {/* <Card className="w-full" overflow={"hidden"} maxW="2xl">
          <CardHeader
            background={"#F3F4F6"}
            paddingTop={"3"}
            paddingBottom={"3"}
          >
            <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
              <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />

              <Box>
                <Heading size="sm">Segun Adebayo</Heading>
                <p>Creator, Chakra UI</p>
              </Box>
            </Flex>
          </CardHeader>
          <Box width={"full"} className="border-b border-b-[#E5E7EB] mx-2 py-4">
            <p className="text-[#6B7280] text-base ml-2">
              All team members Submissions
            </p>
          </Box>
          <CardBody padding={0}>
            <CardFooter
              justify="space-between"
              flexWrap="wrap"
              className="space-y-2"
            >
              <Box>Recent team Document Submissions</Box>
              {[1, 2, 3, 4, 5].map((v) => {
                return <SubmissionItem key={v.toString()} />;
              })}
            </CardFooter>
          </CardBody>
        </Card> */}
      </div>
    </SectionTitle>
  );
}

interface SubmissionItemProps {
  leading?: ReactNode;
  name?: string;
  title?: string;
  tag?: ReactNode;
  trailing?: string;
}

function SubmissionItem(props: SubmissionItemProps) {
  return (
    <Box w={"full"} padding={4} className="hover:bg-slate-100 rounded-md">
      <Flex direction={"row"} alignItems={"center"} gap={4}>
        <Flex direction={"row"} alignItems={"center"} gap={2.5}>
          <FilePdfFill />
          <CircleFill size={"10px"} color={"#6B7280"} />
          <Avatar size={"sm"} />
        </Flex>

        <Flex direction={"row"} alignItems={"center"} gap={1}>
          <p className="text-base font-medium ">Camila Cabelo</p>
          <p className="text-sm text=[#6B7280]">
            Document for scale algorithms
          </p>
          <Badge fontSize={"smaller"}>New</Badge>
        </Flex>
        <div className="flex-grow"></div>
        <Badge variant="solid">2</Badge>
        <ChevronRight />
      </Flex>
    </Box>
  );
}

export default TeamSubmission;
