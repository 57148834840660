"use client";
import EmptyDashboard from "@/components/shared/EmptyDashboard";
import SectionTitle from "@/components/shared/SectionTitle";

import SessionItem from "@/components/shared/SessionItem";
import { Session } from "@/interfaces/session";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

function TeamSession() {
  const sessions: Session[] = [
    {
      id: "1",
      calender: "",
      date: "September,2023",
      description:
        "All participants will be briefed on what is expected of them",
      time: "10:00 - 13:00",
      link: "",
      title: "Zambia Hackathon",
      participants: [],
    },
  ];

  return (
    <>
      <SectionTitle title="Projects Submitted for Review" />

      <Tabs>
        <TabList>
          <Tab>All</Tab>
          <Tab>Ongoing Session</Tab>
          <Tab>Upcoming Session</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <div className="flex justify-start items-center flex-wrap md:gap-6 gap-4 ">
              {sessions.length == 0 ? (
                <EmptyDashboard />
              ) : (
                sessions.map((v) => <SessionItem key={v.id} {...v} />)
              )}
            </div>
          </TabPanel>
          <TabPanel></TabPanel>

          <TabPanel></TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default TeamSession;
