import styles from "@styles/Timer.module.scss";
import { Utils } from "@utils/utils";
import moment from "moment";
import { useEffect, useRef, useState } from "react";

interface HacklabTimeProps {
  timeOnly?: boolean;
}
function HacklabTimer(props: HacklabTimeProps) {
  const someDate = useRef(moment("20230901"));
  const [now, setNow] = useState<any>(null);

  useEffect(() => {
    setInterval(() => {
      setNow(moment(someDate.current.diff(moment(new Date()))));
    }, 1000);
  }, []);

  return (
    <div className="w-fit mx-auto text-center">
      {props.timeOnly && (
        <div className="text-white  font-bold text-base md:text-lg">
          <p
            style={{ letterSpacing: "0.105em" }}
            className="font-[Poppins-Regular]"
          >
            Ready to launch in...
          </p>
        </div>
      )}
      {now && (
        <div className={styles.timer}>
          <div className={styles.time}>
            <p className={styles.title}>Days</p>
            <p className={styles.value}>{Utils.toDouble(now.days())}</p>
          </div>
          <div className={styles.divider}>:</div>
          <div className={styles.time}>
            <p className={styles.title}>Hours</p>
            <p className={styles.value}>{Utils.toDouble(now.hours())}</p>
          </div>
          <div className={styles.divider}>:</div>
          <div className={styles.time}>
            <p className={styles.title}>Minutes</p>
            <p className={styles.value}>{Utils.toDouble(now.minutes())}</p>
          </div>
          <div className={styles.divider}>:</div>
          <div className={styles.time}>
            <p className={styles.title}>Seconds</p>
            <p className={styles.value}>{Utils.toDouble(now.seconds())}</p>
          </div>
        </div>
      )}
    </div>
  );
}

HacklabTimer.propTypes = {};

export default HacklabTimer;
