"use client";
import { HacklabHeader } from "@components/shared/Header";
import HacklabTimer from "@components/shared/Timer";
// import { Inter } from "next/font/google";
import styles from "@styles/Home.module.scss";

import { Gift } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

// const inter = Inter({ subsets: ["latin"] });

const Home = () => {
  return (
    <>
      <main className={styles.main + ""}>
        <div className="h-fit">
          <HacklabHeader />
        </div>
        <div className="flex flex-col justify-center gap-10 sm:gap-14 md:gap-[100px] ml-4 md:ml-[200px] ">
          <h2 className="text-left text-[50px] lg:text-[64px] text-[#D4E5FF] font-sans font-bold tracking-[0.005em]">
            Hacklab Hackathon <br />
            <span className="text-[#fff]">Zambia 2023</span>
          </h2>

          <div className="button-div flex flex-row items-center  ">
            <Link to={"/auth/login"}>
              <button className="text-[#fff] flex gap-3 items-center rounded-md mr-[25px] justify-between bg-h-primary hover:bg-opacity-80 transition-colors py-4 px-6  ">
                <img src="/assets/svgs/hacklab-h.svg" alt="" />
                Register
              </button>
            </Link>

            <Link to={"/dashboard"}>
              <button className="text-[#fff] gap-3 flex items-center justify-between bg-transparent  border-white border-2 rounded-md hover:bg-opacity-95 py-4 px-6 ">
                <Gift />
                Donate
              </button>
            </Link>
          </div>
        </div>
        <div>
          <HacklabTimer />
        </div>
      </main>
    </>
  );
};

export default Home;
