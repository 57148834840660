import { RootState } from "@/redux/app.store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const ProtectedRoute = ({ children }: any) => {
  const state = useSelector((state: RootState) => state.authReducer);
  const navigate = useNavigate();
  useEffect(() => {
    if (!state.userInfo) {
      navigate("/auth");
    }
  }, [state, navigate]);

  return children;
};
