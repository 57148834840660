// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

export const FirebaseConfig = {
    apiKey: "AIzaSyAk20cKnZEOhbeM1hQ0yawZ-QV2xUknX_8",
    authDomain: "hacklab-hackathon.firebaseapp.com",
    projectId: "hacklab-hackathon",
    storageBucket: "hacklab-hackathon.appspot.com",
    messagingSenderId: "558243224068",
    appId: "1:558243224068:web:ab230b6ba0bcb55b0d3351",
    measurementId: "G-J0YK435VP6"
};


// Initialize Firebase
const firebaseApp = initializeApp(FirebaseConfig, 'http://127.0.0.1:9099');

export default firebaseApp;
