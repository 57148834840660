import ConnectedApps from "@components/shared/ConnectedApps";
import SideBarItem from "@components/shared/SideBarItem";
import {
  Award,
  FileEarmarkCheck,
  People,
  PlayCircle,
  Stickies,
} from "react-bootstrap-icons";
import { Menu, MenuItem } from "react-pro-sidebar";
import TeamSettings from "./TeamSettings";

function TeamsSideBar() {
  return (
    <section className="pages-wrapper ml-3 mt-4">
      <TeamSettings showTitle={false} />

      {/* <div className="nav flex flex-row items-center w-[80%] pt-3 pb-3 mt-6 justify-between">
        <span className="flex flex-row items-center">
          
          <h2 className="text-[18px] text-[#1F2937] m-0">Project</h2>
        </span>
        <ChevronDown className="mr-4" />
      </div> */}
      <Menu>
        <MenuItem disabled>
          <h2 className="text-muted font-semibold ">PAGES</h2>
        </MenuItem>
        <SideBarItem leading={<Stickies />} title="Project" href="projects" />
        <SideBarItem
          leading={<FileEarmarkCheck size={20} />}
          title="Project Scores"
          href="scores"
          // onClick={() => {}}
        />
        <SideBarItem
          leading={<Award size={20} />}
          title="Achievements"
          href="achievements"
          // onClick={() => {}}
        />
        {/* <SideBarItem
          leading={<FileEarmarkCheck size={20} />}
          title="Team Submission"
          href="./submissions"
          onClick={() => {}}
        /> */}
        {/* <SideBarItem
          leading={<People size={20} />}
          title="Team Submissions"
          href="submissions"
          onClick={() => {}}
        /> */}
        <SideBarItem
          leading={<PlayCircle size={20} />}
          title="Sessions"
          href="sessions"
          // onClick={() => {}}
        />

        <ConnectedApps />
      </Menu>
    </section>
  );
}

export default TeamsSideBar;
