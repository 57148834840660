import { RootState } from "@/redux/app.store";
import {
  Avatar,
  AvatarGroup,
  Button,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import TeamSettingsModal from "@components/modals/TeamSettingsModal";
import { CodeSquare, Pencil, PeopleFill } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { TeamMember } from "../../interfaces/timeline";
import JoinTeamModal from "../modals/JoinTeamModal";
import NewTeamModal from "../modals/NewTeamModal";
import FooterLeft from "../shared/FooterLeft";

interface TeamSettingsProp {
  showTitle: boolean;
  showBottom?: boolean;
}

function TeamSettings(props: TeamSettingsProp) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const newTeamModal = useDisclosure();
  const joinTeam = useDisclosure();

  const teamState = useSelector((state: RootState) => state.teamStore);

  const profile: TeamMember[] = teamState.team?.members ?? [];

  function open() {
    onOpen();
  }

  return (
    <div>
      <TeamSettingsModal isOpen={isOpen} onClose={onClose} />
      <JoinTeamModal isOpen={joinTeam.isOpen} onClose={joinTeam.onClose} />
      <NewTeamModal
        isOpen={newTeamModal.isOpen}
        onClose={newTeamModal.onClose}
      />

      <section className="entire-teamsettings-wrapper">
        {props.showTitle && (
          <div className="upper-side flex flex-row justify-between items-center">
            <h2 className="text-muted font-sans font-bold">Team Settings</h2>
            <IconButton
              icon={<PeopleFill />}
              onClick={() => {
                if (teamState.hasTeam) {
                  onOpen();
                } else {
                  newTeamModal.onOpen();
                }
              }}
              aria-label="teams settings"
            ></IconButton>
          </div>
        )}

        <div className="lower-side m-4 flex flex-row">
          {teamState.hasTeam ? (
            <AvatarGroup size="md" onClick={open}>
              {profile.map((item) => {
                return (
                  <Avatar key={item.name} name={item.name} src={item.image} />
                );
              })}
              {teamState.isAdmin && (
                <Avatar variant={"ghost"} icon={<Pencil />} />
              )}
            </AvatarGroup>
          ) : (
            <div className="md:space-x-6 space-x-4  w-fit mx-auto gap-2">
              <Button
                onClick={newTeamModal.onOpen}
                variant={"outline"}
                leftIcon={<CodeSquare />}
                className="mx-auto "
              >
                Create new team
              </Button>
              <Button
                onClick={joinTeam.onOpen}
                variant={"outline"}
                leftIcon={<CodeSquare />}
                className="mx-auto "
              >
                Join a team
              </Button>
            </div>
          )}
        </div>
      </section>

      {props.showBottom && (
        <section className="footer-left mt-[60px]">
          <FooterLeft />
        </section>
      )}
    </div>
  );
}

TeamSettings.propTypes = {};

export default TeamSettings;
