import logo from "@assets/svgs/hacklab.svg";
import { Link } from "react-router-dom";

function HacklabLogo(props: any) {
  return (
    <Link className="block" to={"/"}>
      <img src={logo} className="md:w-[180px] w-[140px] h-[90px]" alt="" />
    </Link>
  );
}

HacklabLogo.propTypes = {};

export default HacklabLogo;
